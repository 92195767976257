import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class StatusCheckService {

    static statusCheck = (requestData: any) => {
        const url = ApiEndpoints.STATUS_CHECK;
        const response = FetchApi(url, JSON.stringify(requestData));
        return response;
    }
}