import { useState, useEffect } from 'react';
import { viewOrderService } from '../Services/ViewOrderService';
import { OrderModel } from '../model/OrderModel';
import OrderDetail from './OrderDetail';
import { constant } from '../Constants/constant';
import EmptyCart from './EmptyCart';
import { PulseLoader } from 'react-spinners';

const UserOrder = () => {
  const [orders, setOrders] = useState<OrderModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [ordersPerPage] = useState<number>(10);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  useEffect(() => {
    viewOrdersList();
  }, []);

  const viewOrdersList = async () => {
    const requestData = { orderId: "" };
    try {
      const response = await viewOrderService.viewOrder(requestData);
      const parsedData = JSON.parse(response);
      // console.log("Order response:", parsedData);
      if (parsedData.data && parsedData.data.length > 0) {
        setOrders(parsedData.data);
      } else {
        setOrders([]);
      }

      setLoading(false);
    } catch (error) {
      // console.error("Error fetching order details:", error);
      setError("Failed to load orders. Please try again later.");
      setLoading(false);
    }
  };
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleViewDetails = (orderId: string) => {
    setSelectedOrderId(orderId);
  };

  if (loading) {
    return (
      <div className="loader-container">
      <PulseLoader color="#0f6cb2" loading={loading} size={12} />
      <span>Loading Orders...</span>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const totalPages = Math.ceil(orders.length / ordersPerPage);

  return (
    <div className="h-100 block-space scroll-content">
      {selectedOrderId ? (
        <OrderDetail orderId={selectedOrderId} />
      ) : (
        <>
          {orders.length === 0 ? (
            <EmptyCart />
          ) : (
            <>
              {currentOrders.map((order) => (
                <table key={order.orderId} className="table table-striped border bg-white w-100 order-table-section">
                  <tbody>
                    <tr>
                      <td colSpan={1} className="text-left">
                        <strong>Order Id:</strong> &nbsp; {order.orderId}
                      </td>
                      <td colSpan={1} className="m-0">
                        <strong>Order Value:</strong> &nbsp; ₹{order.total}
                      </td>
                      <td colSpan={3}>
                        <strong>Order Date:</strong> &nbsp; {order.orderDate}
                      </td>
                      <td colSpan={1} className='text-right'>
                      <button
                          className="btn btn-primary text-white ms-4"
                          onClick={() => handleViewDetails(order.orderId)}
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                    {order.orderDetails?.map((detail, index) => (
                      <tr key={index}>
                        <td colSpan={1}>
                          {detail.productInventory.inventoryImages ? (
                            <img
                            width={60}
                            src={
                              detail.productInventory?.inventoryImages?.[0]?.image
                                ? `${constant.IMAGE_API_URL}${detail.productInventory.inventoryImages[0].image}`
                                : "/assets/images/no-image.png"
                            }
                            alt="Product Image"
                          />
                          
                          ) : (
                            <span>No image available</span>
                          )}
                        </td>
                        <td>{detail.description}</td>
                        <td>₹{detail.subTotal}</td>
                        <td>{detail.sts}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <div className="">
                <div className='row'>
                  <div className='col-lg-6 d-flex justify-content-between align-items-center'>
                  <button
                  className="pagination-btn"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                  </div>
                  <div className='col-lg-6 d-flex justify-content-end'>
                  <button
                  className="pagination-btn"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>

                  </div>

                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UserOrder;
