import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class ViewWishListService{
    
    static viewWishList = (requestData: any) =>{
        const url = ApiEndpoints.VIEW_WISHLIST;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;
    }
}