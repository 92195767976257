export const ApiEndpoints = {
    AUTH: "/auth",

    INDEX: "/index",

    REGISTER: "/register",

    LOGIN: "/login",

    PRODUCT_DETAIL: "/product-details",

    ADD_TO_CART: "/add-to-cart",

    VIEW_CART: "/cart",

    REMOVE_CART: "/remove-cart",

    ADD_TO_WISHLIST: "/add-to-wishlist",

    VIEW_WISHLIST: "/wishlist",
    
    REMOVE_WISHLIST: "/wishlist/delete",

    SAVE_LATER: "/save-later",

    PRODUCT_FILTER: "/product-category-by-id",

    PROFILE_UPDATE: "/profile-update",

    ADDRESS_LIST: "/address",

    ADD_ADDRESS: "/address/store",

    DELETE_ADDRESS: "/address/delete",

    GET_COUNTRY: "/get-country",

    GET_STATE: "/get-state",

    RESET_PASSWORD: "/reset-password",

    VIEW_ORDER: "/order",

    PLACE_ORDER: "/place-order",

    INVOICE_DOWNLOAD: "/invoice-download",

    ORDER_STATUS: "/order/update-status",

    APPLY_COUPON: "/apply-coupon",

    CARTSUMMERY_NON_LOGIN: "/cart-summery-nonLogin",

    ORDER_FAILED: "/order-failed",

    PAYMENT_CALLBACK: "/callback",

    STATUS_CHECK: "/status-check",

    FORGOT_PASSWORD: "/forgot-password"

}