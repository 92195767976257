
import { ProductInventoriesModel } from "./ProductInventoriesModel";

export class ViewWishListModel {
    wishlistId: number;
    productName: string;
    mrp: number;
    salePrice: number;
    inventoryId: number;
    productInventories:ProductInventoriesModel;
    constructor() {
       this. wishlistId = 0;
        this.productName = "";
        this.salePrice = 0;
        this.mrp = 0;
        this.inventoryId = 0;
        this.productInventories = new ProductInventoriesModel() ;
      
    }
  }