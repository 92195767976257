import { useEffect, useState } from "react";
import { ViewCartService } from "../Services/ViewCartService";
import { SummeryModel } from "../model/SummeryModel";
import { ResponseModel } from "../model/ResponseModel";
import { CouponService } from "../Services/CouponService";
import { toast, ToastContainer } from "react-toastify";
import { PlaceOrderService } from "../Services/PlaceOrderService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { OrderFailService } from "../Services/OrderFailService";
import { clear } from "../features/cartSlice";
import { PaymentCallbackService } from "../Services/PaymentCallbackService";
import { RazorpayModal } from "../model/RazorpayModal";

interface ChekoutPaymentProps {
  billingId: string;
  shippingId: string;
}

function Summery({ billingId, shippingId }: ChekoutPaymentProps) {
  const navigate = useNavigate();
  const [summery, setSummery] = useState<SummeryModel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [couponCode, setCouponCode] = useState('');
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  const [coupon, setCoupon] = useState('');
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false); // Track the processing state
  useEffect(() => {
    loadViewCart();
  }, []);

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value);
  };

  const applyCoupon = async () => {
    if (!couponCode) {
      toast.info("Please enter a coupon code", {
        autoClose: 500,
      });
      return;
    }

    const requestData = { coupon: couponCode };
    try {
      const response = await CouponService.coupon(requestData);
      const parsedResponse: ResponseModel = JSON.parse(response);

      if (parsedResponse.statusCode === '1000' && parsedResponse.statusMsg === 'SUCCESS') {
        toast.success("Coupon applied successfully!", {
          autoClose: 500,
        });

        const discountVal = parseFloat(parsedResponse.data.discountVal.replace(/,/g, ""));
        const gstVal = parseFloat(parsedResponse.data.gstVal.replace(/,/g, ""));
        const totalVal = parseFloat(parsedResponse.data.totalVal.replace(/,/g, ""));

        setSummery((prevState) => ({
          ...prevState,
          discount: discountVal,
          gst: gstVal,
          total: totalVal,
          price: prevState?.price ?? 0,
          qty: prevState?.qty ?? 0,
          subTotal: prevState?.subTotal ?? 0,
          taxableVal: prevState?.taxableVal ?? 0
        }));
        setCoupon(couponCode);
        setIsCouponApplied(true);

      } else if (parsedResponse.statusCode === '0000' && parsedResponse.statusRemark === 'Coupon is not valid!') {
        toast.error("Coupon is not valid!");
      } else if (parsedResponse.statusCode === '0000' && parsedResponse.statusRemark.startsWith('Total amount is less than the minimum required')) {
        const errorMessage = parsedResponse.statusRemark.replace(/0$/, '');
        toast.error(errorMessage);
      } else {
        toast.error(`Unexpected error: ${parsedResponse.statusRemark}`);
      }
    } catch (error) {
      toast.error("An error occurred while applying the coupon.");
    }
  };

  const loadViewCart = async () => {
    const requestData = "";
    try {
      const response = await ViewCartService.viewCart(requestData);
      const parsedData = JSON.parse(response);
      setSummery(parsedData.data);
    } catch (error) {
      console.error("Error loading cart data:", error);
      setError("Failed to load cart data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const placeOrder = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      toast.error("You are not authenticated. Please log in.");
      return;
    }

    const requestData = {
      shippingId,
      billingId,
      payMode: "",
      coupon: coupon || ""
    };
    setIsProcessing(true);
    try {
      const response = await PlaceOrderService.placeOrder(requestData);
      // console.log("Place order response:", response);
      if (response.statusCode === "1000" && response.data) {
        //  console.log("Response Type:", response.data.type);
        if (response.data.type === "razorpay") {
          // console.log("Razorpay condition ");
          Razorpay(response.data);
        }
        else if (response.data.type === "finsole") {
          // console.log("Finsole condition ");

          // console.log(response.data);
          FINSOLE(response.data);
        }
        else {
          console.log("Unsupported type:", response.data.type);
        }
      }
      else if (response.statusCode === "0000" && response.statusRemark === "Shipping Address Not Selected") {
        toast.error("Shipping Address Not Selected");
      } else {

        console.log("Order not successful or response is missing 'type' and 'sts'");
      }

    } catch (error) {
      console.error("Error placing order:", error);
    }
    setIsProcessing(false);
  };
  // Razorpay Payment Gateway Integration
  const Razorpay = (data: RazorpayModal) => {
    const options = {
      key: data.key,
      amount: data.amount,
      orderId: data.orderId,
      currency: 'INR',
      name: data.companyName,
      image: data.companylogo,
      description: "Order Payment",
      order_id: data.razorpayOrderId,
      handler: function (response: RazorpayModal) {

        // console.log("razorpay_order_id :" + response.razorpay_order_id + ", razorpay_payment_id :" + response.razorpay_payment_id + ", razorpay_signature: " + response.razorpay_signature);

        const razorpay_order_id = response.razorpay_order_id;
        const razorpay_signature = response.razorpay_signature;
        const razorpay_payment_id = response.razorpay_payment_id;
        const orderId = data.orderId;

        // console.log("razorpay_order_id", razorpay_order_id, "razorpay_signature", razorpay_signature, "razorpay_payment_id", razorpay_payment_id);

        PaymentCallback(razorpay_order_id, razorpay_signature, razorpay_payment_id, orderId)
        // navigate('/thankyou');
      },
      prefill: {
        name: data.customer.name,
        email: data.customer.email,
        contact: data.customer.contact,
      },
      notes: {
        address: data.customer.address || "Not provided",
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function () {

          handleOrderCancellation(data.orderId);

        }
      }
    };

    const razorpayOpen = new (window as any).Razorpay(options);
    razorpayOpen.open();
  };

  const PaymentCallback = async (
    razorpay_order_id: string,
    razorpay_payment_id: string,
    razorpay_signature: string,
    orderId: string
  ) => {
    const requestData = {
      razorpay_order_id,
      razorpay_payment_id,
      razorpay_signature,
      orderId,
    };

    // console.log("Request data for callback:", requestData);

    const response = await PaymentCallbackService.paymentCallback(requestData);

    if (
      response.statusCode === "1000" &&
      response.statusMsg === "SUCCESS" &&
      response.statusRemark.includes("Thank you for your order. We are processing it and will notify you once its shipped.")
    ) {
      navigate("/thankyou");
    }
  };


  const handleOrderCancellation = async (orderId: string) => {
    console.log("order id in order cancel function " + orderId)
    const requestData = { orderId: orderId };
    console.log("request order id for order cancel" + requestData);
    const response = await OrderFailService.orderFail(requestData);
    dispatch(clear());
    navigate("/customer");
  };

  function FINSOLE(response: any) {
    const redirectUrl = response.response.redirect_url;
    console.log(redirectUrl);
    window.location.href = redirectUrl;
    // dispatch(clear());
  }

  return (
    <div className="panel panel-default">
      <ToastContainer />
      {loading ? (
        <p>Loading summary...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          <div className="panel-heading">
            <h4 className="unicase-checkout-title">Cart Summary ({summery?.qty || 0})</h4>
          </div>
          <div className="panel-body p-0">
            <ul className="list-group list-group-flush">
              <li className="list-group-item summary-key">
                <div>Price:</div>
                <div>₹{(summery?.taxableVal || 0).toFixed(2)}</div>

              </li>
              <li className="list-group-item summary-key">
                <div>Discount:</div>
                <div>₹{summery?.discount || 0}</div>
              </li>
              <li className="list-group-item summary-key">
                <div>GST:</div>
                <div>₹{summery?.gst && !isNaN(summery.gst) ? summery.gst.toFixed(2) : "0.00"}</div>
              </li>
              <li className="list-group-item summary-key">
                <div>Shipping Charges:</div>
                <div>₹ 00</div>
              </li>
              <li className="list-group-item summary-key">
                <div>Pay Amount:</div>
                <div>₹{summery?.total || 0}</div>
              </li>
            </ul>
          </div>
        </div>
      )}

      <div className="coupons mt-3">Coupons & Discounts</div>
      <div className="row mt-3">
        <div className="col-md-12 d-flex justify-content-between">
          <div className="form-group">
            <input
              type="text"
              name="coupon"
              className="form-control unicase-form-control text-input coupon"
              id="coupon"
              placeholder="Apply Coupon"
              value={couponCode}
              onChange={handleCouponChange}
              disabled={isCouponApplied}
            />
          </div>
          <div className="form-group">
            <button
              className="btn btn-primary form-control unicase-form-control py-3  applyCoupon"
              onClick={applyCoupon}
              disabled={isCouponApplied}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="col-md-12 d-flex justify-content-center">
        <button
  type="button"
  className="btn btn-primary order-btn w-100 m-0"
  onClick={placeOrder}
  disabled={isProcessing || !shippingId || !billingId} // ✅ Disable if no address is selected
>
  {isProcessing ? "Processing..." : "Place Order"}
</button>

        </div>
      



        <div className="row align-items-center justify-content-center">
       
        </div>
      </div>
    </div>
  );
}

export default Summery;
