import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class productDetailService {
  static productdetail = (requestData: any) => {
    const url = ApiEndpoints.PRODUCT_DETAIL;
    const response = FetchApi(url, JSON.stringify(requestData));
    return response;
  };
}
