import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class productFilterService {
  static productfilter = (requestData: any) => {
    const url = ApiEndpoints.PRODUCT_FILTER;
    const response = FetchApi(url, JSON.stringify(requestData));
    return response;
  };
}
