import { useEffect, useState } from "react";
import { productDetailService } from "../Services/ProductDetailService";
import { useLocation, useNavigate } from "react-router-dom";
import { constant } from "../Constants/constant";
import { Auth } from "../Auth/Auth";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addToCartService } from "../Services/AddToCartService";
import { addtoWishListService } from "../Services/AddWishListService";
import * as ReactDOMServer from "react-dom/server";
import { Parser as HtmlToReactParser } from "html-to-react";
import { useDispatch, useSelector } from "react-redux";
import { add, CartItem } from "../features/cartSlice";
import { ProductInventoriesModel } from "../model/ProductInventoriesModel";
import { WishlistItem } from "../features/wishListSlice";
import { addwishlist } from "../features/wishListSlice";
import { RootState } from "../redux/Store";
import { InventoryImageModel } from "../model/InventoryImageModel";
import ReactImageMagnify from "react-image-magnify";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./customOwlCarousel.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PulseLoader } from "react-spinners";
import StarRatings from "react-star-ratings";
const ProductDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [relatedProducts, setRelatedProducts] = useState<ProductInventoriesModel[]>([]);
  const [variants, setVariants] = useState<ProductInventoriesModel[]>([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const handleThumbnailClick = (index: number) => {
    setSelectedImageIndex(index);
  };
  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const cartItems = useSelector((state: RootState) => state.cart.items);

  const handleAddToCart = async (product: ProductInventoriesModel) => {
    // Log the available quantity
    // console.log("Available quantity of the product:", product.qty);

    const cartItem: CartItem = {
      inventoryId: product.inventoryId,
      inventoryName: product.inventoryName,
      salePrice: product.salePrice,
      qty: quantity,  // Assuming quantity is defined elsewhere in your code
      mrp: product.mrp,
      inventoryImages: product.inventoryImages.map((image) => image.image),
      totalPrice: product.salePrice * quantity,
      cartId: 0,
      slug: product.slug,
      inventory: product.inventory,
      negative_inv: product.negative_inv
    };

    const authToken = localStorage.getItem("authToken");

    if (!authToken) {
      if (product.negative_inv === 1 && product.inventory === 1) {
        const itemExists = cartItems.some(
          (item) => item.inventoryId === cartItem.inventoryId
        );
        if (itemExists) {
          toast.info("This product is already in your cart!", { autoClose: 1000 });
        } else {
          dispatch(add(cartItem));
        }
      } else if (product.negative_inv === 0 && product.inventory === 1) {
        // Case 2: Inventory is 1, negative_inv is 0 - Check available qty
        if (product.qty < cartItem.qty) {
          toast.error(`Requested quantity (${cartItem.qty}) exceeds the available stock (${product.qty}).`, { autoClose: 2000 });
        }
        else {
          const itemExists = cartItems.some(
            (item) => item.inventoryId === cartItem.inventoryId
          );
          if (itemExists) {
            toast.info("This product is already in your cart!", { autoClose: 1000 });
          } else {
            // Add the item to Redux (non-logged in user)
            dispatch(add(cartItem));
          }
        }
      } else if (product.inventory === 0 && product.negative_inv === 0) {
        // Case 3: Inventory is 0, negative_inv is 0 - Add to cart directly without checking available qty
        const itemExists = cartItems.some(
          (item) => item.inventoryId === cartItem.inventoryId
        );
        if (itemExists) {
          toast.info("This product is already in your cart!", { autoClose: 1000 });
        } else {
          // Add the item to Redux (non-logged in user)
          dispatch(add(cartItem));
        }
      } else {
        // Any other case (if needed)
        toast.error("Unable to add this product to the cart.", { autoClose: 2000 });
      }
    } else {
      // Case for logged-in users (API call)
      try {
        const requestData = {
          inventoryId: product.inventoryId,
          qty: quantity,
          cartId: "",
        };

        const response = await addToCartService.addtocart(requestData);
        let parsedResponse = typeof response === "string" ? JSON.parse(response) : response;
        // console.log("API Response:", parsedResponse);
        if (parsedResponse.statusCode === "1000" && parsedResponse.statusMsg === "SUCCESS") {
          const apiCartItem: CartItem = {
            inventoryId: parsedResponse.data.inventoryId,
            inventoryName: parsedResponse.data.description,
            salePrice: parsedResponse.data.price,
            qty: parsedResponse.data.qty,
            mrp: parsedResponse.data.mrp,
            inventoryImages: product.inventoryImages.map((image) => image.image),
            totalPrice: parsedResponse.data.total,
            cartId: parsedResponse.data.cartId,
            slug: parsedResponse.data.slug,
            inventory: parsedResponse.data.inventory,
            negative_inv: parsedResponse.data.negative_inv
          };
          // Add the item to Redux after API call
          dispatch(add(apiCartItem));
        } else if (parsedResponse.statusCode === "0000" && parsedResponse.statusMsg === "ERROR") {
          if (parsedResponse.statusRemark && parsedResponse.statusRemark.includes("exceeds the available stock")) {
            toast.error(parsedResponse.statusRemark, { autoClose: 3000 });
          } else if (parsedResponse.statusRemark && parsedResponse.statusRemark.includes("This product is already added to the cart")) {
            toast.info("This product is already in your cart!", { autoClose: 1000 });
          }
        }
      } catch (error) {
        console.error("Error occurred while adding to cart:", error);
        toast.error("Error occurred while adding to cart", { autoClose: 1000 });
      }
    }
  };


  const handleAddToWishList = async (id: number) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      toast.error("Please login to add products to the wishlist", {
        autoClose: 1000,
        onClose: () => {
          navigate("/SignInForm");
        },
      });
      return;
    }

    const requestData = { inventoryId: id };
    try {
      const response = await addtoWishListService.addToWishList(requestData);
      let parsedResponse;
      if (typeof response === "string") {
        parsedResponse = JSON.parse(response);
      } else {
        parsedResponse = response;
      }
      if (
        parsedResponse.statusCode === "1000" &&
        parsedResponse.statusMsg === "SUCCESS"
      ) {
        toast.success("Product added to wishlist successfully!", {
          autoClose: 1000,
        });
      } else if (
        parsedResponse.statusCode === "0000" &&
        parsedResponse.statusMsg === "ERROR"
      ) {
        if (
          parsedResponse.statusRemark.includes(
            "This product is already added to the wishlist"
          )
        ) {
          toast.info("This product is already in your wishlist!", {
            autoClose: 1000,
          });
        } else {
          toast.error("Error adding product to wishlist", { autoClose: 1000 });
        }
      }
    } catch (error) {
      toast.error("Error adding product to wishlist", { autoClose: 1000 });
    }
  };

  const decryptId = (encryptedId: any) => {
    return atob(encryptedId);
  };


  const location = useLocation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const encryptedId = params.get('invId');
  const inventoryId = decryptId(encryptedId);
  const searchParams = new URLSearchParams(location.search);
  const currentInvId = Number(decryptId(searchParams.get("invId")));

  const [productDetails, setProductDetails] = useState<any>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const htmlInput = "";
  const htmlToReactParser = HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(htmlInput);
  const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

  useEffect(() => {
    fetchInfo();
  }, [inventoryId]);



  const fetchInfo = async () => {
    setLoading(true);

    const requestData = { inventoryId };

    try {
      const response = await productDetailService.productdetail(requestData);

      if (response) {
        const parsedResponse = JSON.parse(response);
        // console.log(parsedResponse);
        const productDetails = parsedResponse.data.product;
        const encryptedDescription = productDetails.description;
        const relatedProducts = parsedResponse.data.relatedProducts;
        const variants = parsedResponse.data.variants;
        const decryptedDescription = await Auth.getDecryptText(encryptedDescription);
        const encryptedLongDescription = productDetails.longDescription;
        const decryptedLongDescription = await Auth.getDecryptText(encryptedLongDescription);
        const encryptedHowToUse = productDetails.howToUse;
        const decryptedHowToUse = await Auth.getDecryptText(encryptedHowToUse);

        productDetails.description = decryptedDescription;
        productDetails.longDescription = decryptedLongDescription;
        productDetails.howToUse = decryptedHowToUse;

        setProductDetails(productDetails);
        setRelatedProducts(relatedProducts);
        setVariants(variants);
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    } finally {
      setLoading(false);
    }
  };

  const encryptId = (id: any) => {
    return btoa(id);
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };
  return (
    <>
      <ToastContainer />
      <div>{reactHtml}</div>
      <div className="breadcrumb">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="list-inline list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">
                {productDetails?.productInventory?.inventoryName ??
                  "Loading..."}{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row single-product">
            <div className="col-md-12">
              <div className="detail-block">
                <div className="row  wow fadeInUp">
                  <div className="col-xs-12 col-sm-12 col-md-5 gallery-holder">
                    <div className="product-item-holder size-big single-product-gallery small-gallery">
                      {/* Main Product Image */}
                      <div id="owl-single-product">
                        <div
                          className="single-product-gallery-item" style={{ position: 'relative', width: '100%', height: '100%' }}
                        >

                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "image not available",
                                isFluidWidth: true,
                                src:
                                  productDetails?.productInventory?.inventoryImages?.[selectedImageIndex]?.image
                                    ? `${constant.IMAGE_API_URL}${productDetails.productInventory.inventoryImages[selectedImageIndex].image}`
                                    : "/assets/images/no-image.png",
                              },
                              largeImage: {
                                src:
                                  productDetails?.productInventory?.inventoryImages?.[selectedImageIndex]?.image
                                    ? `${constant.IMAGE_API_URL}${productDetails.productInventory.inventoryImages[selectedImageIndex].image}`
                                    : "/assets/images/no-image.png",
                                width: 1200, // You can adjust this value for a stronger zoom effect
                                height: 1800, // Adjust as needed
                              },
                              zoomContainerStyle: {
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 99,
                                overflow: "hidden",
                              },
                              lensStyle: {
                                backgroundColor: "rgba(255, 255, 255, 0.5)",
                              },
                              enlargedImagePosition: "over",
                              imageClassName: "product-image",
                            }}
                          />

                        </div>
                        <div >
                        </div>
                      </div>

                      {/* Thumbnails */}
                      <div className="single-product-gallery-thumbs gallery-thumbs">
                        <div className="owl-wrapper-outer">
                          <div className="owl-wrapper">
                            <div
                              className="owl-item"
                              style={{ display: "flex" }}
                            >
                              {productDetails?.productInventory?.inventoryImages
                                ?.length > 0 ? (
                                productDetails.productInventory.inventoryImages.map(
                                  (
                                    image: InventoryImageModel,
                                    index: number
                                  ) => (
                                    <div className="item" key={image.imageId}>
                                      <a
                                        className={`horizontal-thumb ${index === selectedImageIndex
                                          ? "active"
                                          : ""
                                          }`}
                                        onClick={() =>
                                          handleThumbnailClick(index)
                                        } // Set selected image on click
                                      >
                                        <img
                                          className="img-responsive"
                                          width={100}
                                          alt={`Product image ${index + 1}`}
                                          src={`${constant.IMAGE_API_URL}${image?.image}`}
                                        />
                                      </a>
                                    </div>
                                  )
                                )
                              ) : (
                                <div>Loading images...</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7 product-info-block">

                    <div className="cursor-overlay"> </div>

                    <div className="product-info">
                      <h1 className="name mt-0 mb-0">
                        {productDetails?.productInventory?.inventoryName ??
                          "Loading..."}
                      </h1>
                      <div className="description-container m-t-20">
                        {productDetails?.description ? (
                          <div className="des-all"
                            dangerouslySetInnerHTML={{
                              __html: productDetails.description,
                            }}
                          />
                        ) : (
                          "Loading description..."
                        )}
                      </div>
                      <div className="price-container info-container">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="price-box">
                              <span className="price">
                                ₹
                                {productDetails?.productInventory
                                  ?.salePrice ?? "Loading..."}
                              </span> &nbsp;
                              <span className="price-strike">
                                ₹
                                {productDetails?.productInventory?.mrp ??
                                  "Loading..."}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-6">

                          </div>
                        </div>
                        {/* /.row */}
                      </div>
                      {/* /.price-container */}
                      <div className="quantity-container info-container">
                        <div className="row d-flex align-items-center">

                          <div className="col-lg-3 col-sm-3">
                            <div className="row align-items-center">
                              <div className="d-flex align-items-center quantity-icon">
                                <div
                                  className="arrow minus gradient me-2"
                                  onClick={decreaseQuantity}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                >
                                  <span className="ir">-</span>{" "}

                                </div>
                                <input
                                  type="text"
                                  id="qty"
                                  value={quantity}
                                  readOnly
                                  className="text-center"
                                  style={{ width: "60px" }}
                                />
                                <div
                                  className="arrow plus gradient ms-2"
                                  onClick={increaseQuantity}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                >
                                  <span className="ir">+</span>{" "}

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-9 col-sm-5 d-flex gap-6">
                            <div className="action-button">
                              <button
                                onClick={() =>
                                  handleAddToCart(
                                    productDetails?.productInventory
                                  )
                                }
                                className="btn btn-primary addToCart"
                              >
                                <i className="fa fa-shopping-cart inner-right-vs" />
                                ADD TO CART
                              </button>
                            </div>
                            <div className="favorite-button">
                              <button
                                onClick={() =>
                                  handleAddToWishList(productDetails?.productInventory?.inventoryId)
                                }
                                className="btn btn-warning wishlist wishlist-icon"
                              >
                                <i className="fa fa-heart wishlist-heart" /> &nbsp; ADD TO WISHLIST
                              </button>
                            </div>
                          </div>
                        </div>
                        {variants.length > 0 && (
                          <>
                            <div className="variant-div">
                              {/* <p className="mb-2 fw-bold">Variants</p> */}
                              <div className="d-flex flex-wrap gap-2">
                                {variants.map((inventory) => (
                                  <Link
                                    key={inventory.inventoryId}
                                    to={`/productDetail/${inventory.slug}?invId=${encryptId(inventory.inventoryId)}`}
                                    className={`fw-bold variant-btns ${currentInvId === inventory.inventoryId ? "active" : ""}`}
                                  >
                                    {inventory.inventoryName}
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="row">
                          <div className="col-lg-10 w-100">
                            <div className="tab-content tabs-all">
                              <div className="product-tabs">
                                <ul id="product-tabs" className="nav nav-tabs nav-tab-cell">
                                  <li className="active">
                                    <a data-toggle="tab" href="#description">
                                      DESCRIPTION
                                    </a>
                                  </li>
                                  <li>
                                    <a data-toggle="tab" href="#review">
                                      REVIEW
                                    </a>
                                  </li>
                                  {/* <li>
                        <a data-toggle="tab" href="#tags">
                          TAGS
                        </a>
                      </li> */}
                                </ul>

                              </div>
                              <div id="description" className="tab-pane in active">
                                <div className="product-tab mt-0">
                                  {productDetails?.description ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: productDetails.longDescription,
                                      }}
                                    />
                                  ) : (
                                    "Loading description..."
                                  )}
                                </div>
                              </div>
                              {/* /.tab-pane */}
                              <div id="review" className="tab-pane">
                                <div className="product-tab">
                                  <div className="product-reviews" id="reviewData">
                                    <h4 className="title">Customer Reviews</h4>
                                    <div className="reviews">
                                      <div className="review">
                                        <div className="review-title">
                                          <span className="summary">
                                            <span className="date">
                                              <i className="fa fa-user" />{" "}
                                            </span>
                                            review
                                            <span className="fa fa-star {{ $i <= $review->rating ? 'checked' : '' }}"></span>
                                          </span>
                                          <span className="date">
                                            <i className="fa fa-calendar" />
                                            <span>
                                              <span></span>
                                            </span>
                                          </span>
                                        </div>
                                        <div className="text">{ } </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="product-tabs inner-bottom-xs  wow fadeInUp">
                <div className="row">
                  <div className="col-sm-3">

                    {/* /.nav-tabs #product-tabs */}
                  </div>
                  <div className="col-sm-9">

                  </div>
                </div>
              </div>


              <section className="section featured-product wow fadeInUp">
                <h3 className="section-title">Related Products</h3>
                {loading ? (
                  <div className="loader-container">
                    <PulseLoader color="#007bff" size={10} />
                  </div>
                ) : relatedProducts.length > 0 ? (
                  <Slider {...settings}>
                    {relatedProducts.map((inventory) => (
                      <div key={inventory.inventoryId} className="item item-carousel">
                        <div className="products">
                          <div className="product">
                            {/* Product Image */}
                            <div className="product-image">
                              <div className="image">
                                <Link to={`/productDetail/${inventory.slug}?invId=${encryptId(inventory.inventoryId)}`}
                                  onClick={() => window.scrollTo(0, 0)}>
                                <img
                                src={
                                  inventory.inventoryImages?.[0]?.image
                                    ? `${constant.IMAGE_API_URL}${inventory.inventoryImages[0].image}`
                                    : "/assets/images/no-image.png"
                                }
                                alt={inventory.inventoryName}
                              />

                                </Link>
                              </div>
                            </div>


                            {/* Product Details */}
                            <div className="product-info text-left">
                              <h3 className="name mt-0 mb-0">
                                <Link to={`/productDetail/${inventory.slug}?invId=${encryptId(inventory.inventoryId)}`}
                                  onClick={() => window.scrollTo(0, 0)}>
                                  {inventory.inventoryName}
                                </Link>
                              </h3>
                              <StarRatings
                                rating={4}
                                starRatedColor="gold"
                                numberOfStars={5}
                                name="rating"
                                starDimension="15px"
                                starSpacing="3px"
                              />

                              <div className="rating rateit-small"></div>
                              <div className="product-price">
                                <span className="price">₹{inventory.salePrice}</span>
                                <span className="price-before-discount">{inventory.mrp}</span>
                              </div>
                            </div>

                            {/* Cart & Wishlist Actions */}
                            <div className="cart clearfix animate-effect">
                              <div className="action">
                                <ul className="list-unstyled">
                                  <li className="add-cart-button btn-group">
                                    <button
                                      onClick={() => handleAddToCart(inventory)}
                                      className="btn btn-primary icon addToCart"
                                      type="button"
                                    >
                                      <i className="fa fa-shopping-cart"></i>
                                    </button>
                                    <button className="btn btn-primary cart-btn" type="button">
                                      Add to cart
                                    </button>
                                  </li>
                                  <li className="lnk">
                                    <a
                                      onClick={() => handleAddToWishList(inventory.inventoryId)}
                                      className="add-to-cart wishlist"
                                      title="Wishlist"
                                    >
                                      <i className="icon fa fa-heart"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <img src="/assets/images/no-data.jpg" />
                )}

              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductDetail;
