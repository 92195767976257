import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export type WishlistItem = {
  inventoryId: number;
  inventoryName: string;
  salePrice: number;
  mrp: number;
  inventoryImages: string[];
};

const initialState = {
  items: [] as WishlistItem[],
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    addwishlist: (state, action: PayloadAction<WishlistItem>) => {
      const newItem = action.payload;
      
      // Check if the item already exists in the wishlist
      const existingItem = state.items.find(item => item.inventoryId === newItem.inventoryId);
      
      if (!existingItem) {
        // If it doesn't exist, add the new item
        state.items.push(newItem);
        toast.success("Product added to wishlist successfully!", { autoClose: 1000 });
      } else {
        // If the item already exists, show a toast message
        toast.info("This product is already in your wishlist!", { autoClose: 2000 });
      }
    },
    
    removewishlist: (state, action: PayloadAction<WishlistItem>) => {
      state.items = state.items.filter(item => item.inventoryId !== action.payload.inventoryId);
    },
    clearWishlist: (state) => {
      state.items = [];
    },
  },

});

// Export actions and reducer
export const { addwishlist, removewishlist, clearWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
