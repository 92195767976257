const EmptyWishlist = () => {
    return (
      <>
        <div className="empty-cart " id="empCart">
          <img
            width={200}
            src="/assets/images/no-data.jpg"
            alt="Cart Empty"
          />
          <h3 className="class-text-center">Your Wishlist is empty!</h3>
          <p>Add items to it now.</p>
          <a className="btn btn-upper btn-primary outer-left-xs" href="/">
            Shop Now
          </a>
        </div>
      </>
    );
  };
  export default EmptyWishlist;