import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class viewOrderService{
    
    static viewOrder = (requestData: any) =>{
        const url = ApiEndpoints.VIEW_ORDER;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;
    }
}