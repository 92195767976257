import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class IndexService {

    static index = (requestData: any) => {
        const url = ApiEndpoints.INDEX;
        const response = FetchApi(url, JSON.stringify(requestData));
        return response;
    }
}