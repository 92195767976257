import { Link } from "react-router-dom";

const EmptyCart = () => {
  return (
    <>
      <div className="empty-cart " id="empCart">
        <img
          width={200}
          src="/assets/images/no-data.jpg"
          alt="Cart Empty"
        />
        <h3 className="class-text-center">Your cart is empty!</h3>
        <p>Add items to it now.</p>
        <Link to="/"  className="btn btn-upper btn-primary outer-left-xs" >
          Shop Now
        </Link>
      </div>
    </>
  );
};
export default EmptyCart;
