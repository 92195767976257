import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IndexService } from "../Services/IndexService";
import { useSelector } from "react-redux";
import { selectTotalAmount } from "../features/cartSelectors";
import { selectCartItems } from "../features/cartSelectors";
import { RootState } from "../redux/Store";
import { constant } from "../Constants/constant";
import { InfoModel } from "../model/InfoModel";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clearUser } from "../features/userSlice";
import { clearWishlist } from "../features/wishListSlice";
import { clear as clearCart } from "../features/cartSlice";
import PersonIcon from '@mui/icons-material/Person';
import { BasicModal } from "../model/BasicModel";

type SubCategory = {
  subCategoryId: number;
  subCategoryName: string;
  subcategoryImage: string;
};


type Category = {
  categoryId: number;
  categoryName: string;
  subCategories: SubCategory[];
}

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectTotalAmount);
  const [categories, setCategories] = useState<Category[]>([]);
  const [info, setInfo] = useState<InfoModel | null>(null);
  const [basic, setBasic] = useState<BasicModal | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(0);


  const placeholders = [
    "Search for cameras...",
    "Search for a laptop...",
    "Search for other devices...",
    "Search for grocery...",
    "Search for Home Appliances...",
  ];

  useEffect(() => {
    FullDataLoad();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 2400); // Change placeholder every 2.4 seconds

    return () => clearInterval(interval);
  }, [placeholders.length]);

  const FullDataLoad = async () => {
    try {
      const requestData = "";
      const response = await IndexService.index(requestData);
      const parsedData = JSON.parse(response);
      // console.log(parsedData);
      if (parsedData.statusCode === "1000") {
        setCategories(parsedData.data.products);
        setInfo(parsedData.data.info);
        setBasic(parsedData.data.basic);
        document.title = parsedData.data.basic?.title?.trim() ? parsedData.data.basic.title : "Sirf Shopping";
      }
    } catch (error) {
      toast.error("Api Network Error");
    }
  };

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    if (searchTerm.trim() !== "") {
      navigate(`/category/${searchTerm}`, { state: { search: searchTerm } });
      setSearchTerm("");
    }
  };
  const isUserLoggedIn = user && user.firstName;

  return (
    <header className="header-style-1">
      <div className="main-header">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-xs-12 col-sm-12 col-md-2 logo-holder">
              <div className="logo">
                {info ? (
                  <img
                    src={`${constant.IMAGE_API_URL}${info.logo}`}
                    alt="Logo not available"
                  />
                ) : (
                  <p> logo </p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 top-search-holder">
              <div className="search-area">
                <form onSubmit={handleSearch}>
                  <div className="control-group">
                    <ul className="categories-filter animate-dropdown">
                      <li className="dropdown">
                        <a
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          href="#"
                        >
                          Categories
                        </a>
                        <ul className="dropdown-menu" role="menu">
                          {categories.map((category) => (
                            <li
                              key={category.categoryId}
                              className="menu-header"
                            >
                              <Link
                                to={`/category/${category.categoryName}`}
                                state={{ categoryId: category.categoryId }}
                              >
                                {category.categoryName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                    <input
                      className="search-field"
                      name="search"
                      placeholder={placeholders[placeholderIndex]}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="search-button"></button>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 animate-dropdown top-cart-row d-flex justify-content-end">
              <ul className="list-unstyled d-flex align-items-center holder">
                <li>
                  {isUserLoggedIn ? (
                    <Link to="/customer">
                      <PersonIcon className="custom-icon" /> &nbsp; Welcome, {user.firstName}!
                    </Link>
                  ) : (
                    <Link to="/signinform">
                      <PersonIcon className="custom-icon" /> &nbsp; Hello, sign in!
                    </Link>
                  )}
                </li>
              </ul>

              <div className="dropdown dropdown-cart">
                <Link to="cart" className="lnk-cart">
                  <div className="items-cart-inner">
                    <div className="basket">
                      <i className="glyphicon glyphicon-shopping-cart"></i>
                    </div>
                    <div className="basket-item-count">
                      <span className="count">{cartItems.length}</span>
                    </div>
                    <div className="total-price-basket">
                      <span className="total-price">
                        <span className="sign">₹ </span>
                        <span className="value">
                          {isNaN(totalAmount) || totalAmount === null
                            ? "0.00"
                            : totalAmount.toFixed(2)}
                        </span>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-nav animate-dropdown">
        <div className="container">
          <div className="yamm navbar navbar-default p-0" role="navigation">
            <div className="navbar-header">
              <button
                data-target="#mc-horizontal-menu-collapse"
                data-toggle="collapse"
                className="navbar-toggle collapsed"
                type="button"
              >
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
            </div>
            <div className="nav-bg-class">
              <div
                className="navbar-collapse collapse"
                id="mc-horizontal-menu-collapse"
              >
                <ul className="nav navbar-nav">
                  <li className="active dropdown yamm-fw">
                    <Link to="/">Home</Link>
                  </li>
                  {categories.map((category) => (
                    <li key={category.categoryId} className="dropdown yamm mega-menu">
                      <a className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        {category.categoryName}
                      </a>
                      {category.subCategories.length > 0 && (
                        <ul className="dropdown-menu">
                          {category.subCategories.map((subCategory) => (
                            <li key={subCategory.subCategoryId} className="d-flex align-items-center">
                              {/* {subCategory.subcategoryImage && (
                      <img
                        src={`${constant.IMAGE_API_URL}${subCategory.subcategoryImage}`}
                        alt={subCategory.subCategoryName}
                        className="me-2"
                        style={{ width: "30px", height: "30px", objectFit: "cover" }}
                      />
                    )} */}
                              <Link
                                to={`/category/${subCategory.subCategoryName}`}
                                state={{ subcategoryId: subCategory.subCategoryId }}
                              >
                                {subCategory.subCategoryName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

