import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class GetStateService{
    
    static getState = (requestData: any) =>{
        const url = ApiEndpoints.GET_STATE;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;

        
    }
}