import React from 'react'

function Checkout2() {
  return (
    <div>
      {/* start part */}


      {/* import { useState } from "react";
import Summery from "./Summery";
import ChekoutLogin from "./ChekoutLogin";
import CheckoutBillingAddress from "./CheckoutBillingAddress";
import ChekoutShippingAddress from "./ChekoutShippingAddress";
import { AddressModel } from "../model/AddressModel"; */}
{/* 
const Checkout = () => {
  const [addressLists, setAddressLists] = useState<AddressModel[]>([]);
  const [billingId, setBillingId] = useState<string>("");
  const [shippingId, setShippingId] = useState<string>("");

  const updateAddressList = (newAddress: AddressModel) => {
    setAddressLists((prevState) => {
      const addressIndex = prevState.findIndex(
        (address) => address.addressId === newAddress.addressId
      );
      if (addressIndex !== -1) {
        const updatedAddressList = [...prevState];
        updatedAddressList[addressIndex] = newAddress;
        return updatedAddressList;
      } else {
        return [...prevState, newAddress];
      }
    });
  };

  const handleAddressSelect = (addressId: string) => {
    setBillingId(addressId);
   
  };

  const handleShippingIdSelect = (addressId: string) => {
    setShippingId(addressId);

  };

  return (
    <div>
      <div className="breadcrumb">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="list-inline list-unstyled">
              <li>
                <a href="#">Home</a>
              </li>
              <li className="active">Checkout</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="body-content">
        <div className="container">
          <div className="checkout-box">
            <div className="row">
              <div className="col-md-8">
                <div className="panel-group checkout-steps" id="accordion">
                  <ChekoutLogin />
                  <CheckoutBillingAddress
                    addressLists={addressLists}
                    updateAddressList={updateAddressList}
                    onSelectBillingAddress={handleAddressSelect}
                  />
                  <ChekoutShippingAddress
                    addressLists={addressLists}
                    setAddressLists={setAddressLists}
                    onSelectShippingAddress={handleShippingIdSelect}
                  />
                  
                </div>
              </div>
              <div className="col-md-4">
                <div className="checkout-progress-sidebar">
                  <div className="panel-group" id="cartSummery">
                    <Summery billingId={billingId} shippingId={shippingId} />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}












{/* end part */}
    </div>
  )
}

export default Checkout2