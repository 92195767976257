import { InventoryImageModel } from "./InventoryImageModel";

export class ProductInventoriesModel {
    inventoryId: number; 
    inventoryName: string;
    inventoryImages: InventoryImageModel[]; 
    mrp: number;
    qty: number;
    salePrice: number;
    slug: string;
    sts: string;
    reason: string;
    inventory: number;
    negative_inv: number;
    constructor() {
        this.inventoryId = 0;
        this.inventoryName = "";
        this.inventoryImages = [];
        this.mrp = 0;
        this.qty = 0; 
        this.salePrice = 0; 
        this.slug = "";
        this.sts ="";
        this.reason= "";
        this.inventory=0;
        this.negative_inv=0;
    }
}