import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { RegisterModel } from "../model/RegisterModel";
import { FetchApi } from "./FetchApi";

export class RegisterService{
    
    static register = (requestData: RegisterModel) =>{
        const url = ApiEndpoints.AUTH+ApiEndpoints.REGISTER;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;
    }
}