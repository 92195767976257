import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AddressListService } from "../Services/AddressListService";
import { AddressModel } from "../model/AddressModel";
import Swal from "sweetalert2";
import { DeleteAddressService } from "../Services/DeleteAddressService";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddAddressService } from "../Services/AddAddressService";
import { GetCountryService } from "../Services/GetCountryService";
import { CountryModel } from "../model/CountryModel";
import { GetStateService } from "../Services/GetStateService";
import { StateModel } from "../model/StateModel";
import { useUser } from "../Contexts/UserContext";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import { PulseLoader } from "react-spinners";

const UserAddress = () => {
  const { user } = useUser();
  const [addressList, setAddressList] = useState<AddressModel[]>([]);
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<string>("");
  const [selectedStateId, setSelectedStateId] = useState<string>("");
  const [states, setStates] = useState<StateModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressModel | null>(
    null
  );

  // Fetch the address list
  useEffect(() => {
    fetchAddressList();
    loadCountries();
  }, []);

  const fetchAddressList = async () => {
    setLoading(true);
    try {
      const response = await AddressListService.addressList("");
      const parsedData = typeof response === "string" ? JSON.parse(response) : response;
      const addressData = parsedData?.data || [];

      const addressList = addressData.map((item: any) => ({
        addressId: item.addressId,
        address: item.address,
        mobile: item.mobile,
        countryId: item.countryId,
        stateId: item.stateId,
        country: item.country,
        state: item.state,
        city: item.city,
        pinCode: item.pinCode,
      }));

      setAddressList(addressList);
    } catch (error) {
      setAddressList([]);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      address: "",
      mobile: "",
      countryId: "",
      stateId: "",
      city: "",
      pinCode: "",
      addressId: "",
    },
    validationSchema: Yup.object({
      address: Yup.string().required("Address is required"),
     mobile: Yup.string()
        .required("Please Enter Your Mobile Number")
        .matches(/^\d+$/, "Only numbers are allowed")
        .matches(/^(?:\+91)?[6-9]\d{9}$/, "Enter a valid Indian mobile number (10 digits, starts with 6-9"),
      countryId: Yup.string().required("Country is required"),
      stateId: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      pinCode: Yup.string()
        .required("Pin code is required")
        .matches(/^\d{6}$/, "Pin code must be 6 digits"),
    }),
    onSubmit: async (values) => {
      const requestData = prepareData(values);
      try {
        const response = await AddAddressService.addAddress(requestData); // Store response
        const parsedResponse = JSON.parse(response);
        // console.log("Add Address API Response:", response); // Log the response
    
        if (parsedResponse?.statusCode === "1000") {
          toast.success(parsedResponse?.statusRemark || "Address saved successfully", { autoClose: 500 });
    
          fetchAddressList();
          formik.resetForm();
          setSelectedAddress(null);
          setIsAddFormVisible(false);
        } else {
          toast.error(parsedResponse?.statusRemark || "Failed to save address", { autoClose: 500 });
        }
      } catch (error) {
        console.error("Error saving address:", error);
        toast.error("Failed to save address");
      }
    }
    
    
  });

  const prepareData = (values: any): AddressModel => ({
    addressId: values.addressId || "",
    address: values.address,
    mobile: values.mobile,
    countryId: values.countryId,
    stateId: values.stateId,
    city: values.city,
    pinCode: values.pinCode,
    country: countries.find((c) => c.countryId === values.countryId)?.countryName || "",
    state: states.find((s) => s.stateId === values.stateId)?.stateName || "",
  });

  const loadCountries = async () => {
    try {
      const response = await GetCountryService.getCountry("");
      setCountries(JSON.parse(response).data);
    } catch (error) {
      console.error("Error fetching country list", error);
    }
  };

  const loadStates = async (countryId: string) => {
    try {
      const response = await GetStateService.getState({ countryId });
      const parsedData = JSON.parse(response);
      // console.log(parsedData);
      setStates(Array.isArray(parsedData.data) ? parsedData.data : []);
    } catch (error) {
      console.error("Error fetching state list", error);
      setStates([]);
    }
  };

  useEffect(() => {
    if (selectedCountryId) loadStates(selectedCountryId);
  }, [selectedCountryId]);

  const handleEditAddress = async (address: AddressModel) => {
    setSelectedAddress(address); 
    setSelectedCountryId(address.countryId);
    setSelectedStateId(address.stateId);
  
    await loadStates(address.countryId);
  
    formik.setValues({
      address: address.address,
      mobile: address.mobile,
      countryId: address.countryId,
      stateId: address.stateId,
      city: address.city,
      pinCode: address.pinCode,
      addressId: address.addressId, 
    });
  
    setIsAddFormVisible(true);
  };
  
  const handleDeleteAddress = async (addressId: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Want to delete this address?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await DeleteAddressService.deleteAddress({ addressId });
        toast.success("Address removed successfully", { autoClose: 500 });
        fetchAddressList();
      }
    });
  };
  const handleCancel = () => {
    setIsAddFormVisible(false);
  };

  return (
    <>
      <div className="add-address all active d-flex justify-content-end">
      {!isAddFormVisible && (
  <Link
    to="#"
    onClick={() => {
      formik.resetForm(); 
      setSelectedAddress(null);
      setSelectedCountryId(""); 
      setSelectedStateId("");
      setIsAddFormVisible(true);
    }}
    className="new-add btn btn-upper outer-left-xs"
    title="Add New Address"
    id="plus-btn"
  >
    <i className="fa fa-plus pr-2"></i> &nbsp; Add New Address
  </Link>
)}


      </div>

      {isAddFormVisible && (
        <form
          onSubmit={formik.handleSubmit}
          className="address-form mt-4 block-space"
          autoComplete="off"
        >
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label className="info-title" htmlFor="address">
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  name="address"
                  className="form-control unicase-form-control text-input address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="invalid-feedback d-block">{formik.errors.address}</div>
                )}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label className="info-title" htmlFor="mobile">
                  Mobile<span>*</span>
                </label>
                <input
                  type="text"
                  name="mobile"
                  className="form-control unicase-form-control text-input mobile"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className="invalid-feedback d-block">{formik.errors.mobile}</div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label className="info-title" htmlFor="country">
                  Country<span>*</span>
                </label>
                <select
                  name="countryId"
                  className="form-control unicase-form-control text-input"
                  onChange={(e) => {
                    setSelectedCountryId(e.target.value);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.countryId}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.countryId} value={country.countryId}>
                      {country.countryName}
                    </option>
                  ))}
                </select>
                {formik.touched.countryId && formik.errors.countryId && (
                  <div className="invalid-feedback d-block">{formik.errors.countryId}</div>
                )}
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label className="info-title" htmlFor="state">
                  State<span>*</span>
                </label>
                <select
                  name="stateId"
                  className="form-control unicase-form-control text-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.stateId}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.stateId} value={state.stateId}>
                      {state.stateName}
                    </option>
                  ))}
                </select>
                {formik.touched.stateId && formik.errors.stateId && (
                  <div className="invalid-feedback d-block">{formik.errors.stateId}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label className="info-title" htmlFor="city">
                  City<span>*</span>
                </label>
                <input
                  type="text"
                  name="city"
                  className="form-control unicase-form-control text-input city"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="invalid-feedback d-block">{formik.errors.city}</div>
                )}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label className="info-title" htmlFor="pincode">
                  Pin Code<span>*</span>
                </label>
                <input
                  type="text"
                  name="pinCode"
                  className="form-control unicase-form-control text-input pin_code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pinCode}
                />
                {formik.touched.pinCode && formik.errors.pinCode && (
                  <div className="invalid-feedback d-block">{formik.errors.pinCode}</div>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end" style={{ display: "flex", gap: "10px" }}>
            <button
              type="submit"
              className="btn btn-primary outer-left-xs mr-2 update-btn"
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-danger cancle"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      {!isAddFormVisible && (
        <>
          {loading ? (
          <div className="loader-container">
          <PulseLoader color="#0f6cb2" loading={loading} size={12} />
          <span>Loading Address list...</span>
          </div>
          ) : addressList.length === 0 ? (
            <div className="no-address text-center">
             <img src="/assets/images/no-data.jpg" className="no-data"/>
            </div>
          ) : (
            <div className="address-list">
              {addressList.map((address) => (
                <div key={address.addressId} className="address-card ">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="address-info">
                      <span className="head ">
                        {user?.firstName}  {user?.lastName} 
                      </span>
                      <br/>
                      <span className="user-address">
                        {address.mobile}
                      </span>
                      <br/>
                      
                      <span className="user-address">
                       
                        <ul>
                          <li> {address.address} </li>
                          <li>  {address.city}  {address.country}  {address.state}  {address.pinCode}</li>
                        </ul>
                      </span>
                    </div>

                    {/* Action buttons */}
                    <div className="address-actions d-flex align-items-center">
                      <Link to="#" onClick={() => handleEditAddress(address)} className="edit-icon-btn m-4">
                        <EditNoteIcon/> &nbsp; Edit
                      </Link>
                      <a
                        className="edit-icon-btn delete-icon"
                        onClick={() => handleDeleteAddress(address.addressId)}
                      >
                        <DeleteIcon/> &nbsp; Delete
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UserAddress;
