import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment, Suspense, lazy } from "react";
import { toast, ToastContainer } from "react-toastify";
import { constant } from "../Constants/constant";
import EmptyCart from "./EmptyCart";
import Swal from "sweetalert2";
import { RemoveCartService } from "../Services/RemoveCartService";
import { ViewCartService } from "../Services/ViewCartService";
import { ViewCartModel } from "../model/ViewCartModel";
import { saveLaterService } from "../Services/SaveLaterService";
import { addToCartService } from "../Services/AddToCartService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../redux/Store";
import { selectCartItems, selectTotalAmount } from "../features/cartSelectors";
import { add, decrease, increase, remove, } from "../features/cartSlice";
import { SummeryNonLogin } from "../Services/SummeryNonLoginService";
import { PulseLoader } from "react-spinners";
import { Inventory } from "@mui/icons-material";
import { StatusCheckService } from "../Services/StatusCheckService";
const LoggedInCartSummary = lazy(() => import('./LoggedInCartSummary'));
const NonLoggedInCartSummary = lazy(() => import('./NonLoggedInCartSummary'));

function Cart() {
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [cartData, setCartData] = useState<ViewCartModel[]>([]);
  const [isUpdatingQuantityAll, setIsUpdatingQuantityAll] = useState(false);


  const [data, setData] = useState<any>(null);
  const [savelater, setSaveLater] = useState<ViewCartModel[]>([]);
  const [summeryNonLogin, setsummeryNonLogin] = useState<any>(null);
  const dispatch = useDispatch();
  const cartDataFromRedux = useSelector((state: RootState) => state.cart.items);
  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectTotalAmount);
  const isLoggedIn = localStorage.getItem('authToken');

  useEffect(() => {
    const loadViewCart = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
          const requestData = "";
          const response = await ViewCartService.viewCart(requestData);
          const parsedData = JSON.parse(response);
          // console.log(parsedData);
          setData(parsedData.data);
          setCartData(parsedData.data.cart || []);
          setSaveLater(parsedData.data.saveLater || []);
        } else {
          if (cartDataFromRedux.length > 0) {
            const mappedCartData: ViewCartModel[] = cartDataFromRedux.map(
              (item) => {
                const viewCart = new ViewCartModel();
                viewCart.inventoryId = item.inventoryId;
                viewCart.productInventory.inventoryImages = item.inventoryImages
                  ? item.inventoryImages.map((imageUrl, index) => ({
                    image: imageUrl,
                    imageId: index,
                  }))
                  : [];
                viewCart.cartId = item.cartId || 0;
                viewCart.productInventory.inventoryName = item.inventoryName;
                viewCart.productInventory.slug = item.slug;
                viewCart.price = item.salePrice;
                viewCart.productInventory.mrp = item.mrp;
                viewCart.qty = item.qty;
                viewCart.total = item.totalPrice;
                return viewCart;
              }
            );
            setCartData(mappedCartData);
          } else {
            setCartData([]);
          }
        }
      } catch (error) {
        toast.error("Error loading cart data.");
      } finally {
        setLoading(false);
      }
    };
    loadViewCart();
  }, [cartDataFromRedux]);

  const cart = useSelector((state: RootState) => state.cart.items);
  useEffect(() => {
    const loadSummeryNonLogin = async () => {
      // Check if authToken is not present in localStorage
      const authToken = localStorage.getItem('authToken');

      if (!authToken) {
        try {
          const requestData = {
            carts: cart.map((item) => ({
              inventoryId: item.inventoryId,
              qty: item.qty,
            })),
          };

          // Call the API to load summary for non-login users
          const response = await SummeryNonLogin.summeryNonLogin(requestData);
          const parsedData = JSON.parse(response);

          setsummeryNonLogin(parsedData.data);
        } catch (error) {
          toast.error("Error loading summary data for non-login.");
          console.error("Error loading summary data:", error);
        }
      }
    };

    loadSummeryNonLogin();
  }, [cart]);


  const roundToTwoDecimals = (num: number) => {
    return parseFloat(num.toFixed(2));
  };

  const ensureNumber = (value: any): number => {
    return isNaN(Number(value)) ? 0 : Number(value);
  };

  // const increaseQuantity = async (
  //   cartId: number,
  //   type: "cart" | "saveLater"
  // ) => {
  //   const updateState = type === "cart" ? setCartData : setSaveLater;
  //   const items = type === "cart" ? cartData : savelater;

  //   if (!items) return;

  //   const updatedItem = items.find((item) => item.cartId === cartId);
  //   if (updatedItem) {
  //     const newQty = updatedItem.qty + 1;
  //     const updatedItemWithNewQty = {
  //       ...updatedItem,
  //       qty: newQty,
  //       total: roundToTwoDecimals(ensureNumber(updatedItem.price) * newQty),
  //     };

  //     const requestData = {
  //       cartId: updatedItem.cartId,
  //       qty: newQty,
  //       inventoryId: updatedItem.productInventory?.inventoryId || null,
  //     };

  //     if (!localStorage.getItem("authToken")) {
  //       toast.success("Item quantity updated", { autoClose: 500 });
  //       dispatch(increase(updatedItem.inventoryId));
  //       updateState((prevItems) =>
  //         prevItems.map((item) =>
  //           item.cartId === cartId ? updatedItemWithNewQty : item
  //         )
  //       );
  //     } else {
  //       try {
  //         // Call API to update the cart item
  //         const response = await addToCartService.addtocart(requestData);
  //         const parsedResponse =
  //           typeof response === "string" ? JSON.parse(response) : response;

  //         if (
  //           parsedResponse.statusCode === "1000" &&
  //           parsedResponse.statusMsg === "SUCCESS"
  //         ) {
  //           dispatch(increase(updatedItem.inventoryId));  // Dispatch increase action to update the store
  //           toast.success("Item quantity increased", { autoClose: 500 });

  //           // Update the local state after the successful API call
  //           updateState((prevItems) =>
  //             prevItems.map((item) =>
  //               item.cartId === cartId ? updatedItemWithNewQty : item
  //             )
  //           );
  //         } else if (
  //           parsedResponse.statusCode === "0000" &&
  //           parsedResponse.statusMsg === "ERROR"
  //         ) {
  //           const errorMessage = `The requested quantity (${newQty}) exceeds the available stock`;
  //           toast.error(errorMessage, { autoClose: 500 });

  //           // Revert to the original state if the update failed
  //           updateState((prevItems) =>
  //             prevItems.map((item) =>
  //               item.cartId === cartId ? updatedItem : item
  //             )
  //           );
  //         } else {
  //           toast.error("Error updating item quantity.");
  //           updateState((prevItems) =>
  //             prevItems.map((item) =>
  //               item.cartId === cartId ? updatedItem : item
  //             )
  //           );
  //         }
  //       } catch (error) {
  //         toast.error("Error updating item quantity.");
  //         updateState((prevItems) =>
  //           prevItems.map((item) =>
  //             item.cartId === cartId ? updatedItem : item
  //           )
  //         );
  //       }
  //     }
  //   }
  // };



  // const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false); // Global loading state

  const increaseQuantity = async (cartId: number, type: "cart" | "saveLater") => {
    if (isUpdatingQuantityAll) return;

    setIsUpdatingQuantityAll(true);

    const updateState = type === "cart" ? setCartData : setSaveLater;
    const items = type === "cart" ? cartData : savelater;

    if (!items) return;

    const updatedItem = items.find((item) => item.cartId === cartId);
    if (!updatedItem) return;

    const inventoryId = updatedItem.inventoryId || updatedItem.productInventory?.inventoryId;
    const newQty = updatedItem.qty + 1;

    try {
      let response;
      if (!localStorage.getItem("authToken")) {
        response = await StatusCheckService.statusCheck({ cartId, qty: newQty, inventoryId });
      } else {
        response = await addToCartService.addtocart({ cartId, qty: newQty, inventoryId });
      }

      const parsedResponse = typeof response === "string" ? JSON.parse(response) : response;

      if (parsedResponse.statusCode === "1000" && parsedResponse.statusMsg === "SUCCESS") {
        toast.success("Item quantity updated", { autoClose: 500 });

        dispatch(increase(updatedItem.inventoryId));

        updateState((prevItems) =>
          prevItems.map((item) =>
            item.cartId === cartId
              ? { ...item, qty: newQty, total: ensureNumber(item.price) * newQty }
              : item
          )
        );
      } else if (parsedResponse.statusCode === "0000") {
        toast.error(`The requested quantity (${newQty}) exceeds the available stock.`, { autoClose: 500 });
      } else {
        toast.error("Error updating item quantity.");
      }
    } catch (error) {
      toast.error("Error updating item quantity.");
    } finally {
      setIsUpdatingQuantityAll(false);
    }
  };
  const decreaseQuantity = async (cartId: number, type: "cart" | "saveLater") => {
    if (isUpdatingQuantityAll) return;

    setIsUpdatingQuantityAll(true); // ✅ Disable all buttons at the start

    const updateState = type === "cart" ? setCartData : setSaveLater;
    const items = type === "cart" ? cartData : savelater;

    if (!items) return;

    const updatedItem = items.find((item) => item.cartId === cartId);
    if (!updatedItem || updatedItem.qty <= 1) {
      setIsUpdatingQuantityAll(false); // ✅ Prevents freeze if quantity is already 1
      return;
    }

    const newQty = updatedItem.qty - 1;

    try {
      if (!localStorage.getItem("authToken")) {
        // ✅ Guest User - Update UI Directly
        dispatch(decrease(updatedItem.inventoryId));
        toast.success("Item quantity decreased", { autoClose: 500 });

        updateState((prevItems) =>
          prevItems.map((item) =>
            item.cartId === cartId
              ? { ...item, qty: newQty, total: ensureNumber(item.price) * newQty }
              : item
          )
        );

        setTimeout(() => setIsUpdatingQuantityAll(false), 300); // ✅ Small delay to prevent UI flickering
        return;
      }

      // ✅ Logged-in User - Call API
      const response = await addToCartService.addtocart({
        cartId: updatedItem.cartId,
        qty: newQty,
        inventoryId: updatedItem.productInventory?.inventoryId || null,
      });

      const parsedResponse = typeof response === "string" ? JSON.parse(response) : response;

      if (parsedResponse.statusCode === "1000" && parsedResponse.statusMsg === "SUCCESS") {
        dispatch(decrease(updatedItem.inventoryId));
        toast.success("Item quantity decreased", { autoClose: 500 });

        updateState((prevItems) =>
          prevItems.map((item) =>
            item.cartId === cartId
              ? { ...item, qty: newQty, total: ensureNumber(item.price) * newQty }
              : item
          )
        );
      } else {
        toast.error("Error updating item quantity.");
      }
    } catch (error) {
      toast.error("Error updating item quantity.");
    } finally {
      setTimeout(() => setIsUpdatingQuantityAll(false), 300); // ✅ Ensures UI updates correctly
    }
  };






  const moveToSaveLater = async (cartId: number) => {
    if (localStorage.getItem("authToken")) {
      const requestData = { cartId };

      try {
        const response = await saveLaterService.savelater(requestData);
        toast.success("Item added to Save For Later", { autoClose: 500 });

        const itemToMove = cartData.find((item) => item.cartId === cartId);

        if (itemToMove) {
          setCartData((prevCart) => {
            return prevCart.filter((item) => item.cartId !== cartId);
          });

          setSaveLater((prevSaveLater) => {
            return [...prevSaveLater, itemToMove];
          });
          dispatch(remove(itemToMove.inventoryId));
        }
      } catch (error) {
        toast.error("Failed to add item to Save For Later", { autoClose: 500 });
      }
    } else {
      toast.error("Please login to move items to Save For Later.", {
        autoClose: 500,
      });
    }
  };

  const moveToCart = async (cartId: number) => {
    const itemToMove = savelater.find((item) => item.cartId === cartId);

    if (!itemToMove) {
      toast.error("Item not found in Save for Later.");
      return;
    }

    const cartItem = {
      inventoryId: itemToMove.inventoryId,
      inventoryName: itemToMove.productInventory?.inventoryName || "No Name",
      salePrice: itemToMove.price,
      qty: itemToMove.qty,
      inventoryImages:
        itemToMove.productInventory?.inventoryImages?.map(
          (img) => `${constant.IMAGE_API_URL}${img.image}`
        ) || [],
      totalPrice: itemToMove.total,
      mrp: itemToMove.productInventory.mrp,
      cartId: itemToMove.cartId,
      slug: itemToMove.productInventory.slug,
      inventory: itemToMove.productInventory.inventory,
      negative_inv: itemToMove.productInventory.negative_inv,
    };

    const requestData = {
      cartId: itemToMove.cartId,
      qty: itemToMove.qty,
      inventoryId: itemToMove.inventoryId,
    };

    try {
      await addToCartService.addtocart(requestData);

      dispatch(add(cartItem));
      setSaveLater((prevSaveLater) => {
        return prevSaveLater.filter((item) => item.cartId !== cartId);
      });

      setCartData((prevCart) => {
        return [...prevCart, itemToMove];
      });
    } catch (error) {
      toast.error("Failed to add item to cart.");
    }
  };

  const handleRemoveProduct = async (
    cartId: number,
    type: "cart" | "saveLater"
  ) => {
    const token = localStorage.getItem("authToken");
    Swal.fire({
      title: "Are you sure?",
      text: "Want to delete this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const requestData = { cartId };

        try {
          if (token) {
            const response = await RemoveCartService.removecart(requestData);
            // console.log(response);
          }

          if (type === "cart") {
            setCartData((prevCart) =>
              prevCart.filter((item) => item.cartId !== cartId)
            );

            const itemToRemove = cartData.find(
              (item) => item.cartId === cartId
            );
            if (itemToRemove) {
              dispatch(remove(itemToRemove.inventoryId));
            }
          } else if (type === "saveLater") {
            setSaveLater((prevSaveLater) =>
              prevSaveLater.filter((item) => item.cartId !== cartId)
            );
          }

          toast.success("Item removed successfully", { autoClose: 500 });
        } catch (error) {
          toast.error(
            `Error removing item from ${type === "cart" ? "cart" : "Saved for Later"
            }`
          );
        }
      }
    });
  };

  const handleProceedToPay = () => {
    if (localStorage.getItem("authToken")) {
      navigate("/checkout");
    } else {
      localStorage.setItem("redirectAfterLogin", "/checkout"); // Store the intended page
      toast.error("Please login to proceed to checkout");
      navigate("/signinform");
    }
  };


  const encryptId = (id: any) => {
    return btoa(id);
  }
  return (
    <>
      <ToastContainer />
      <div className="breadcrumb">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="list-inline list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">Shopping Cart</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="checkout-box">
            <div className="row cart-list myCart" id="myCart">
              <div className="col-lg-8">
                <div className="shopping-cart">
                  <div className="shopping-cart-table">
                    {loading ? (
                      <div className="loader-container">
                        <PulseLoader color="#0f6cb2" loading={loading} size={12} />
                        <span>Loading Cart...</span>
                      </div>
                    ) : cartData.length > 0 ? (
                      <div>
                        <div className="cart-item-list">
                          {cartData.map((item) => (
                            <div key={item.cartId} className="cart-item row">
                              {/* Cart Item Image */}
                              <div className="cart-item-image col-md-3 d-flex">
                                <div>
                                  <Link to={`/productDetail/${item.productInventory.slug}?invId=${encryptId(item.inventoryId)}`}>
                                    <img
                                      className="product-image img-fluid"
                                      src={
                                        item.productInventory.inventoryImages?.[0]
                                          ? `${constant.IMAGE_API_URL}${item.productInventory.inventoryImages[0].image}`
                                          : "/assets/images/no-image.png"
                                      }
                                      alt={item.productInventory.inventoryName}
                                    />
                                  </Link>
                                  <div className="cart-quantity">
                                    <div className="quant-input" style={{ marginLeft: "-68px" }}>
                                      <div className="arrows">
                                        <button
                                          className={`arrow minus gradient ${isUpdatingQuantityAll ? "disabled-btn" : ""}`}
                                          onClick={() => decreaseQuantity(item.cartId, "cart")}
                                          disabled={isUpdatingQuantityAll || item.qty <= 1} // Prevents invalid decrease
                                        >
                                          <span className="ir">-</span>
                                        </button>

                                        <input
                                          type="text"
                                          className="col-xs-3 text-center"
                                          id="qty"
                                          value={item.qty}
                                          readOnly
                                        />

                                        <button
                                          className={`arrow plus gradient ${isUpdatingQuantityAll ? "disabled-btn" : ""}`}
                                          onClick={() => increaseQuantity(item.cartId, "cart")}
                                          disabled={isUpdatingQuantityAll}
                                        >
                                          <span className="ir">+</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>



                                </div>
                              </div>
                              {/* Cart Item Data */}
                              <div className="cart-data col-md-8 mt-3">
                                <h4 className="cart-product-description">
                                  <Link to={`/productDetail/${item.productInventory.slug}?invId=${encryptId(item.inventoryId)}`}>
                                    {item.productInventory?.inventoryName}
                                  </Link>
                                </h4>
                                <div className="cart-item-price">
                                  <span>
                                    Rate: &nbsp; ₹{item.price} &nbsp;
                                    <span style={{ textDecoration: "line-through", color: "#d3d3d3" }}>
                                      ₹{item.productInventory?.mrp}
                                    </span>
                                  </span>
                                </div>
                                <div className="cart-item-total">
                                  <strong>
                                    <span>Amount: ₹{item.total}</span>
                                  </strong>
                                </div>
                                <div className="cart-item-actions">
                                  <div className="action-buttons">
                                    <a
                                      onClick={() => handleRemoveProduct(item.cartId, "cart")}
                                      style={{ color: "#e31212" }}
                                      title="Cancel"
                                      className="icon remove-btn"
                                    >
                                      Remove
                                    </a>
                                    &nbsp; &nbsp;
                                    {localStorage.getItem("authToken") && (
                                      <a
                                        onClick={() => moveToSaveLater(item.cartId)}
                                        style={{ color: "#fdd922" }}
                                        title="Save Later"
                                        className="save-later icon save-btn"
                                      >
                                        Save For Later
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Shopping Cart Buttons */}
                        <div className="shopping-cart-btn order-one">
                          <span>
                            <Link to="/" className="btn btn-upper btn-primary outer-left-xs">
                              Continue Shopping
                            </Link>
                            <button
                              onClick={handleProceedToPay}
                              className="btn btn-upper btn-warning pull-right outer-right-xs"
                            >
                              Proceed To Pay
                            </button>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <EmptyCart />
                    )}
                  </div>
                  <br />
                </div>
              </div>

              <Suspense fallback={<div><h1>Loading...</h1></div>}>
                {isLoggedIn ? (
                  <LoggedInCartSummary
                    cartItems={cartItems}
                    data={data}
                    handleProceedToPay={handleProceedToPay}
                    cartData={cartData}
                    navigate={navigate}
                  />
                ) : (
                  <NonLoggedInCartSummary
                    cartItems={cartItems}
                    summeryNonLogin={summeryNonLogin}
                    handleProceedToPay={handleProceedToPay}
                    cartData={cartData}
                    navigate={navigate}
                  />
                )}
              </Suspense>
            </div>
            <div className=" row ">
              <div className="col-lg-8">
                {
                  savelater.length > 0 && (
                    <div className="shopping-cart mb-5">
                      <div className="shopping-cart-table">
                        <h5 style={{ fontWeight: "bold" }}>Saved for Later</h5>

                        <div className="save-later-item-list">
                          {savelater.map((item, index) => (
                            <div key={index} className="row save-later-item">
                              {/* Cart Item Image */}
                              <div className="cart-item-image col-md-3 d-flex">
                                <div>
                                  <Link to={`/productDetail/${item.productInventory.slug}?invId=${encryptId(item.inventoryId)}`}>
                                  <img
                                      className="product-image img-fluid"
                                      src={
                                        item.productInventory.inventoryImages?.[0]
                                          ? `${constant.IMAGE_API_URL}${item.productInventory.inventoryImages[0].image}`
                                          : "/assets/images/no-image.png"
                                      }
                                      alt={item.productInventory.inventoryName}
                                    />
                                  </Link>


                                  <div className="cart-quantity">
                                    <div className="quant-input" style={{ marginLeft: "-68px" }}>
                                      <div className="arrows">
                                        <div
                                          className="arrow plus gradient"
                                          onClick={() =>
                                            decreaseQuantity(item.cartId, "saveLater")
                                          }
                                        >
                                          <span className="ir">-</span>
                                        </div>
                                        <input
                                          type="text"
                                          className="col-xs-3 text-center ml-5"
                                          id="qty"
                                          value={item.qty}
                                          readOnly
                                        />
                                        <div
                                          className="arrow minus gradient"
                                          onClick={() =>
                                            increaseQuantity(item.cartId, "saveLater")
                                          }
                                        >
                                          <span className="ir">+</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Cart Item Data */}
                              <div className="cart-data col-md-8 mt-3">
                                <h4 className="cart-product-description">
                                  <Link to={`/productDetail/${item.productInventory.slug}?invId=${encryptId(item.inventoryId)}`}>
                                    {item.productInventory?.inventoryName}
                                  </Link>
                                </h4>
                                <div className="cart-item-price">
                                  <span>
                                    Rate: &nbsp; ₹{item.price}
                                    <span style={{ textDecoration: "line-through", color: "#d3d3d3" }}>
                                      ₹{item.productInventory?.mrp}
                                    </span>
                                  </span>
                                </div>
                                <div className="cart-item-total">
                                  <strong>
                                    <span>Amount: ₹{item.total}</span>
                                  </strong>
                                </div>

                                <div className="cart-item-actions">
                                  <div className="action-buttons">
                                    <a
                                      onClick={() =>
                                        handleRemoveProduct(item.cartId, "saveLater")
                                      }
                                      style={{ color: "#e31212" }}
                                      title="Cancel"
                                      className="icon remove-btn"
                                    >
                                      Remove
                                    </a>
                                    &nbsp;&nbsp;
                                    {localStorage.getItem("authToken") && (
                                      <a
                                        onClick={() =>
                                          moveToCart(item.cartId)
                                        }
                                        style={{ color: "#fdd922" }}
                                        title="Save Later"
                                        className="move-cart-btn icon save-btn"
                                      >
                                        Move To Cart
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cart;
