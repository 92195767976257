import { useEffect, useState } from "react";
import Summery from "./Summery";
import { AddressModel } from "../model/AddressModel";
import { useSelector } from "react-redux";
import { RootState } from "../redux/Store";
import { CountryModel } from "../model/CountryModel";
import { StateModel } from "../model/StateModel";
import { AddressListService } from "../Services/AddressListService";
import { GetCountryService } from "../Services/GetCountryService";
import { GetStateService } from "../Services/GetStateService";
import { AddAddressService } from "../Services/AddAddressService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
interface CheckoutPaymentProps {
  billingId: string | undefined;
  shippingId: string | undefined;
}
const Checkout = () => {
  const user = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(true); // Add loading state
  const [continueClicked, setContinueClicked] = useState(false);
  const [addressLists, setAddressLists] = useState<AddressModel[]>([]);
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);
  const [selectedbillingAddress, setSelectedbillingAddress] = useState<AddressModel | null>(null);
  const [selectedshippingAddress, setSelectedshippingAddress] = useState<AddressModel | null>(null);
  const [isBillingAddressSelected, setIsBillingAddressSelected] = useState(false);
  const [isShippingAddressSelected, setIsShippingAddressSelected] = useState(false);
  const [billingId, setBillingId] = useState<string | null>(null);
  const [shippingId, setShippingId] = useState<string | null>(null);
  const [isAddressSelected, setIsAddressSelected] = useState<boolean>(false);
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<string>("");
  const [states, setStates] = useState<StateModel[]>([]);
  const [showChangeButtonlogin, setShowChangeButtonlogin] = useState(false);
  const [showChangeButtonbilling, setShowChangeButtonbilling] = useState(false);
  const [showChangeButtonshipping, setShowChangeButtonshipping] = useState(false);
  const fetchAddressData = async () => {
    try {
      setLoading(true); // Start loading before API call
  
      const requestData = "";
      const response = await AddressListService.addressList(requestData);
      const parsedData = JSON.parse(response);
      // console.log("Address list fetched: ", parsedData);
  
      if (parsedData.data && Array.isArray(parsedData.data)) {
        setAddressLists(parsedData.data);
      } else {
        console.error("Data is not in the expected format:", parsedData.data);
      }
    } catch (error) {
      console.error("Error fetching address list", error);
    } finally {
      setLoading(false); // Stop loading after API call
    }
  };

  const loadCountries = async () => {
    try {
      const response = await GetCountryService.getCountry("");
      const parsedData = JSON.parse(response);
      setCountries(parsedData.data);
      // console.log("Country list on billing page", parsedData);
    } catch (error) {
      console.error("Error fetching country list", error);
    }
  };

  const loadStates = async (countryId: string) => {
    try {
      const requestData = { countryId };
      const response = await GetStateService.getState(requestData);
      const parsedData = JSON.parse(response);
      setStates(parsedData.data);
      // console.log("State list loaded:", parsedData);
    } catch (error) {
      console.error("Error fetching state list", error);
    }
  };

  useEffect(() => {
    loadCountries();
    fetchAddressData();
  }, []);

  useEffect(() => {
    if (selectedCountryId) {
      loadStates(selectedCountryId);
    }
  }, [selectedCountryId]);

  const formik = useFormik({
    initialValues: {
      address: selectedbillingAddress?.address || "",
      mobile: selectedbillingAddress?.mobile || "",
      countryId: selectedbillingAddress?.country || "",
      stateId: selectedbillingAddress?.state || "",
      city: selectedbillingAddress?.city || "",
      pinCode: selectedbillingAddress?.pinCode || "",
      addressId: selectedbillingAddress?.addressId || "",
    },
    validationSchema: Yup.object({
      address: Yup.string().required("Address is required"),
        mobile: Yup.string()
              .required("Please Enter Your Mobile Number")
              .matches(/^\d+$/, "Only numbers are allowed")
              .matches(/^(?:\+91)?[6-9]\d{9}$/, "Enter a valid Indian mobile number (10 digits, starts with 6-9"),
      countryId: Yup.string().required("Country is required"),
      stateId: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      pinCode: Yup.string()
        .required("Pin code is required")
        .matches(/^\d{6}$/, "Pin code must be 6 digits"),
    }),
    onSubmit: async (values) => {
      const requestData = prepareData(values);
      const newAddress = prepareData(values);
      try {
        let response;
        if (values.addressId) {
          response = await AddAddressService.addAddress(requestData);
          toast.success("Address Updated Successfully", {
            autoClose: 500,
          });
        } else {
          response = await AddAddressService.addAddress({
            ...requestData,
          });
          toast.success("Address Saved Successfully", {
            autoClose: 500,
          });
        }
        fetchAddressData();
        //  updateAddressList(newAddress);
        formik.resetForm();
        setIsAddFormVisible(false);
      } catch (error) {
        console.error("Error saving address:", error);
      }
    },
  });

  const prepareData = (values: any): AddressModel => {
    const country = countries.find((c) => c.countryId === values.countryId);
    const state = states.find((s) => s.stateId === values.stateId);

    const addressModel = new AddressModel();
    addressModel.address = values.address;
    addressModel.mobile = values.mobile;
    addressModel.countryId = values.countryId;
    addressModel.stateId = values.stateId;
    addressModel.city = values.city;
    addressModel.pinCode = values.pinCode;
    addressModel.addressId = values.addressId || "";
    addressModel.country = country ? country.countryName : "";
    addressModel.state = state ? state.stateName : "";
    return addressModel;
  };

  const handleEditAddress = (address: AddressModel) => {
    setSelectedbillingAddress(address);
    setSelectedCountryId(address.countryId);

    formik.setValues({
      address: address.address,
      mobile: address.mobile,
      countryId: address.countryId,
      stateId: address.stateId,
      city: address.city,
      pinCode: address.pinCode,
      addressId: address.addressId,
    });
    setIsAddFormVisible(true);
  };
  const handleAddClick = () => {
    setSelectedbillingAddress(null);
    formik.resetForm();
    setIsAddFormVisible(true);
  };

  const handleCancelClick = () => {
    setIsAddFormVisible(false);
    formik.resetForm();
  };
  const handlebillingAddressSelect = () => {
    console.log("addres selected");
    setSelectedbillingAddress(null);
    setIsAddressSelected(false);
    // onSelectBillingAddress("");
    formik.resetForm();
  };

  const handleAddressDeselect = (isShipping: boolean) => {
    if (isShipping) {
      setSelectedshippingAddress(null);
    } else {
      setSelectedbillingAddress(null);
    }
    setIsAddressSelected(false);
  };

  return (
    <div>
      <div className="breadcrumb">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="list-inline list-unstyled">
              <li>
                <a href="#">Home</a>
              </li>
              <li className="active">Checkout</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="body-content">
        <div className="container">
          <div className="checkout-box">
            <div className="row">
              <div className="col-md-8">
                <div className="panel-group checkout-steps" id="accordion">
                  <div className="panel panel-default checkout-step-01">
                    <div className="panel-heading">
                      <div className="row">
                        <div className="col-xs-6">
                          <h4 className="unicase-checkout-title">
                            <a>
                              <span>1</span>LOGIN
                            </a>
                          </h4>
                        </div>
                        <div className="col-xs-6 text-right">
                          {showChangeButtonlogin && (
                            <a
                              data-toggle="collapse"
                              className="btn-center btn btn-primary change"
                              data-parent="#accordion"
                              href="#collapseOne"
                            >
                              View
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      id="collapseOne"
                      className="panel-collapse collapse in"
                    >
                      <div className="panel-body">
                        <div className="row">
                          <div className="col-md-12 col-sm-12 guest-login">
                            <h4 className="checkout-subtitle">
                              Name : {user?.firstName} {user?.lastName}
                            </h4>
                            <p className="text title-tag-line">
                              Email : {user?.email}
                            </p>
                            <a
                              type="button"
                              data-toggle="collapse"
                              className="collapsed btn-upper btn btn-primary checkout-page-button checkout-continue"
                              data-parent="#accordion"
                              href="#collapseTwo"
                              onClick={() => {

                                setShowChangeButtonlogin(true);
                              }}
                            >
                              Continue
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Billing address section */}
                  <div className="panel panel-default checkout-step-02">
                    <div className="panel-heading">
                      <div className="row">
                      <div className="col-xs-6">
                          <h4 className="unicase-checkout-title">
                            <a>
                              <span>2</span> Billing Address
                            </a>
                          </h4>
                        </div>
                        <div className="col-xs-6 text-right">
                          {showChangeButtonbilling && (
                            <a
                              data-toggle="collapse"
                              className="collapsed btn-upper btn btn-primary change"
                              data-parent="#accordion"
                              href="#collapseTwo"
                            >
                              Change
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    <div id="collapseTwo" className="panel-collapse collapse">
                      <div
                        className="col-xs-12 text-right"
                        style={{ padding: "10px 0" }}
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          className="btn-sm btn-primary add cursor-pointer"
                          onClick={handleAddClick}
                        >
                          Add
                        </a>
                      </div>
                      <div className="panel-body checkout-body" id="checkoutAdd">
  {!isAddFormVisible ? (
    <div className="row align-items-center list">
      {loading ? (
        <p>Loading addresses...</p>
      ) : addressLists && addressLists.length > 0 ? (
        addressLists.map((address, index) => (
          <div className="col-md-12" key={index}>
            <div className="row d-flex align-items-center">
              <div className="col-md-12 ps-4">
                <span>
                  <span className="head">
                    {user?.firstName} {user?.lastName} - {address.mobile}
                  </span>
                  <br />
                  {address.address} - {address.country} {address.city}{" "}
                  {address.state} {address.pinCode}
                </span>
              </div>
              <div className="col-md-1 d-flex justify-content-start mt-3">
                <button
                  className="btn btn-primary outer-left-xs mr-2 update-btn"
                  onClick={() => {
                    if (
                      isBillingAddressSelected &&
                      selectedbillingAddress === address
                    ) {
                      setIsBillingAddressSelected(false);
                      setSelectedbillingAddress(null);
                    } else {
                      setIsBillingAddressSelected(true);
                      setSelectedbillingAddress(address);
                    }
                  }}
                >
                  {isBillingAddressSelected && selectedbillingAddress === address
                    ? "Deselect"
                    : "Select"}
                </button>
              </div>
              <div className="col-md-3 mt-3 mx-5">
                <button
                  className="btn btn-warning mr-2 update-btn"
                  onClick={() => handleEditAddress(address)}
                >
                  Edit
                </button>
              </div>
            </div>
            <hr className="hr" />
          </div>
        ))
      ) : (
        <p>No addresses available.</p>
      )}

      {isBillingAddressSelected && (
        <a
          type="button"
          data-toggle="collapse"
          className="collapsed btn-upper btn btn-primary checkout-page-button checkout-continue w-auto"
          data-parent="#accordion"
          href="#collapseThree"
          onClick={() => {
            const billingId = selectedbillingAddress?.addressId ?? null;
            setBillingId(billingId);
            setShowChangeButtonbilling(true);
          }}
        >
          Continue
        </a>
      )}
    </div>
  ) : (
    <form
      onSubmit={formik.handleSubmit}
      className="address-form"
      id="Billing_address"
      autoComplete="off"
    >
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <label className="info-title" htmlFor="address">
              Address<span>*</span>
            </label>
            <input
              type="text"
              name="address"
              className="form-control unicase-form-control text-input address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />
            {formik.touched.address && formik.errors.address && (
              <div style={{ color: "red" }}>{formik.errors.address}</div>
            )}
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <label className="info-title" htmlFor="mobile">
              Mobile<span>*</span>
            </label>
            <input
              type="text"
              name="mobile"
              className="form-control unicase-form-control text-input mobile"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobile}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div style={{ color: "red" }}>{formik.errors.mobile}</div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <label className="info-title" htmlFor="country">
              Country<span>*</span>
            </label>
            <select
              name="countryId"
              className="form-control unicase-form-control text-input"
              onChange={(e) => {
                setSelectedCountryId(e.target.value);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.countryId}
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country.countryId} value={country.countryId}>
                  {country.countryName}
                </option>
              ))}
            </select>
            {formik.touched.countryId && formik.errors.countryId && (
              <div style={{ color: "red" }}>{formik.errors.countryId}</div>
            )}
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <label className="info-title" htmlFor="state">
              State<span>*</span>
            </label>
            <select
  name="stateId"
  className="form-control unicase-form-control text-input"
  onChange={formik.handleChange}
  onBlur={formik.handleBlur}
  value={formik.values.stateId}
>
  <option value="">Select State</option>
  {Array.isArray(states) && states.length > 0 ? (
    states.map((state) => (
      <option key={state.stateId} value={state.stateId}>
        {state.stateName}
      </option>
    ))
  ) : (
    <option disabled>States are not available</option>
  )}
</select>


            {formik.touched.stateId && formik.errors.stateId && (
              <div style={{ color: "red" }}>{formik.errors.stateId}</div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <label className="info-title" htmlFor="city">
              City<span>*</span>
            </label>
            <input
              type="text"
              name="city"
              className="form-control unicase-form-control text-input city"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
            />
            {formik.touched.city && formik.errors.city && (
              <div style={{ color: "red" }}>{formik.errors.city}</div>
            )}
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <label className="info-title" htmlFor="pincode">
              Pin Code<span>*</span>
            </label>
            <input
              type="text"
              name="pinCode"
              className="form-control unicase-form-control text-input pin_code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pinCode}
            />
            {formik.touched.pinCode && formik.errors.pinCode && (
              <div style={{ color: "red" }}>{formik.errors.pinCode}</div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex" style={{ display: "flex", gap: "10px" }}>
        <button type="submit" className="btn btn-primary outer-left-xs mr-2 update-btn">
          Submit
        </button>
        <button type="button" className="btn btn-danger cancle" onClick={handleCancelClick}>
          Cancel
        </button>
      </div>
    </form>
  )}
</div>

                    </div>
                  </div>
                  {/* //shipping address */}
                  <div className="panel panel-default checkout-step-05">
                    <div className="panel-heading">
                      <div className="row">
                        <div className="col-xs-6">
                          <h4 className="unicase-checkout-title">
                            <a>
                              <span>3</span> Shipping Address
                            </a>
                          </h4>
                        </div>
                        <div className="col-xs-6 text-right">
                          {showChangeButtonshipping && (
                            <a
                              data-toggle="collapse"
                              className="collapsed btn-upper btn btn-primary change"
                              data-parent="#accordion"
                              href="#collapseThree"
                            >
                              Change
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    <div id="collapseThree" className="panel-collapse collapse">
                      <div
                        className="panel-body checkout-body"
                        id="shippingAddress"
                      >
                        <div className="row align-items-center list">
                          <div className="row align-items-center list">
                            {addressLists && addressLists.length > 0 ? (
                              addressLists.map((address, index) => (
                                <div className="col-md-12" key={index}>
                                  <div className="row d-flex align-items-center">
                                    <div className="col-md-12 ps-4">
                                      <span>
                                        <span className="head">
                                          {user?.firstName} {user?.lastName} -{" "}
                                          {address.mobile}
                                        </span>
                                        <br />
                                        {address.address} - {address.country}{" "}
                                        {address.city} {address.state}{" "}
                                        {address.pinCode}
                                      </span>
                                    </div>
                                    <div className="col-md-1 d-flex justify-content-start mt-3">
                                      <button
                                        className="btn btn-primary outer-left-xs mr-2 update-btn"
                                        onClick={() => {
                                          if (
                                            isShippingAddressSelected &&
                                            selectedshippingAddress === address
                                          ) {
                                            setIsShippingAddressSelected(false);
                                            setSelectedshippingAddress(null);
                                          } else {
                                            setIsShippingAddressSelected(true);
                                            setSelectedshippingAddress(address);
                                          }
                                        }}
                                      >
                                        {isShippingAddressSelected &&
                                          selectedshippingAddress === address
                                          ? "Deselect"
                                          : "Select"}
                                      </button>
                                    </div>
                                  </div>
                                  <hr className="hr" />
                                </div>
                              ))
                            ) : (
                              <p>No addresses available.</p>
                            )}
                          </div>

                          {selectedshippingAddress && (
                            <a
                              type="button"
                              data-toggle="collapse"
                              className="collapsed btn-upper btn btn-primary checkout-page-button checkout-continue w-auto"
                              data-parent="#accordion"
                              href="#collapseThree"
                              onClick={() => {
                                const shippingId =
                                  selectedshippingAddress?.addressId ?? null;
                                // console.log(
                                //   "Selected shipping Address ID:",
                                //   shippingId
                                // );
                                setShippingId(shippingId);
                                setShowChangeButtonshipping(true);
                              }}
                            >
                              Deliver Here
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Sidebar */}
              <div className="col-md-4">
                <div className="checkout-progress-sidebar">
                  <div className="panel-group" id="cartSummery">
                    <Summery
                      billingId={selectedbillingAddress?.addressId ?? ""}
                      shippingId={selectedshippingAddress?.addressId ?? ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
