import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class AddAddressService{
    
    static addAddress = (requestData: any) =>{
        const url = ApiEndpoints.ADD_ADDRESS;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;
    }
}