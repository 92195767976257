import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { constant } from "../Constants/constant";
import EmptyWishlist from "./EmptyWishlist";
import { ViewWishListModel } from "../model/ViewWishListModel";
import { ViewWishListService } from "../Services/ViewWishListService";
import { RemoveWishListService } from "../Services/RemoveWishListService";
import Swal from "sweetalert2";
import { addToCartService } from "../Services/AddToCartService";
import { add } from "../features/cartSlice";
import { PulseLoader } from "react-spinners";


const WishList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [wishlistData, setWishlistData] = useState<ViewWishListModel[]>([]);

  useEffect(() => {
    const loadWishList = async () => {
      try {
        const requestData = "";
        const response = await ViewWishListService.viewWishList(requestData);
        // console.log("Response:", response);
        const parsedData = JSON.parse(response);

        if (parsedData?.statusCode === "1000") {
          const wishlistItems = parsedData?.data.map((item: any) => {
            const wishListModel = new ViewWishListModel();
            wishListModel.wishlistId = item.wishlistId;
            wishListModel.productName = item.productName;
            wishListModel.mrp = parseFloat(item.mrp);
            wishListModel.salePrice = parseFloat(item.salePrice);
            wishListModel.productInventories = item.productInventories;

            return wishListModel;
          });
          setWishlistData(wishlistItems);
        }
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      } finally {
        setLoading(false);
      }
    };

    loadWishList();
  }, []);


  const handleRemoveWishList = async (wishlistId: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Want to delete this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const requestData = { wishlistId };
          const response = await RemoveWishListService.removeWishList(
            requestData
          );
          // console.log("Remove response:", response);

          let parsedResponse;
          try {
            parsedResponse = JSON.parse(response);
            // console.log("Remove wishlist response:", parsedResponse);
          } catch (parseError) {
            console.error("Error parsing the response:", parseError);

            return;
          }

          if (parsedResponse?.statusCode === "1000" && parsedResponse?.statusRemark === "Item removed successfully") {
            toast.success("Wishlist product deleted successfully", {
              autoClose: 500,
            });
            setWishlistData((prevItems) =>
              prevItems.filter((item) => item.wishlistId !== wishlistId)
            );
          }
        } catch (error) {
          console.error("Error removing item:", error);

        }
      }
    });
  };
  const handleAddToCart = async (id: number, item: ViewWishListModel) => {
    const requestData = { inventoryId: id, qty: 1, cartId: "" };
    try {
      const response = await addToCartService.addtocart(requestData);
      let parsedResponse;
      if (typeof response === "string") {
        parsedResponse = JSON.parse(response);
        if (
          parsedResponse.statusCode === "1000" &&
          parsedResponse.statusMsg === "SUCCESS"
        ) {
          // Add to Redux after successful API response
          dispatch(
            add({
              inventoryId: item.productInventories.inventoryId,
              inventoryName: item.productName,
              salePrice: item.salePrice,
              qty: 1,
              mrp: item.mrp,
              inventoryImages: item.productInventories.inventoryImages.map(
                (img) => img.image
              ),
              totalPrice: item.salePrice,
              cartId: 0,
              slug: item.productInventories.slug,
              inventory: item.productInventories.inventory,
              negative_inv: item.productInventories.negative_inv
            })
          );
        } else if (
          parsedResponse.statusCode === "0000" &&
          parsedResponse.statusMsg === "ERROR"
        ) {
          if (
            parsedResponse.statusRemark.includes(
              "This product is already added to the cart"
            )
          ) {
            toast.info("This product is already in your cart!", {
              autoClose: 1000,
            });
          }
        }
      }
    } catch (error) {
      // console.error("Error adding to cart:", error);
      toast.error("Failed to add to cart", { autoClose: 500 });
    }
  };

  const encryptId = (id: any) => {
    return btoa(id);
  }

  return (
    <>
      <ToastContainer />
      <div className="my-wishlist-page">
        <h4>my wishlist ({wishlistData.length})</h4>
        <div className="row">
          <div className="my-wishlist">
            {loading ? (
              <div className="loader-container">
                <PulseLoader color="#0f6cb2" loading={loading} size={12} />
                <span>Loading Wishlist...</span>
              </div>
            ) : wishlistData.length === 0 ? (
              <EmptyWishlist />
            ) : (
              <div className="">
                <table className="table">
                  <tbody>
                    {wishlistData.map((item) => (
                      <tr key={item.wishlistId}>
                        <td className="col-md-3 text-start">
                          <Link to={`/productDetail/${item.productInventories.slug}?invId=${encryptId(item.productInventories.inventoryId)}`}>
                            <img
                              className="product-image img-fluid"
                              src={
                                item.productInventories?.inventoryImages?.[0]?.image
                                  ? `${constant.IMAGE_API_URL}${item.productInventories.inventoryImages[0].image}`
                                  : "/assets/images/no-image.png"
                              }
                              alt={item.productName}
                            />

                          </Link>
                        </td>
                        <td className="col-md-6 text-start">
                          <div className="product-name">
                            <Link to={`/productDetail/${item.productInventories.slug}?invId=${encryptId(item.productInventories.inventoryId)}`}>
                              {item.productName}
                            </Link>
                          </div>
                          <div className="product-price">
                            <span className="price">₹ {item.salePrice}</span> &nbsp;
                            <span className="price-before-discount text-decoration-line-through d-inline-block text-center">
                              ₹ {item.mrp}
                            </span>
                            {item.salePrice < item.mrp && (
                              <div className="text-success saved">
                                You saved{" "}
                                <span className="text-success">
                                  ₹ {(item.mrp - item.salePrice).toFixed(2)}
                                </span>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="col-md-3 text-start d-flex gap-3 mt-5">
                          <button
                            onClick={() => handleAddToCart(item.productInventories.inventoryId, item)}
                            className="cart-button btn-upper btn btn-primary addToCart mb-1"
                          >
                            Add to cart
                          </button>
                          <button
                            onClick={() => handleRemoveWishList(item.wishlistId)}
                            className="delete-button btn-upper btn wishlistremove mb-1"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WishList;
