import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class AddressListService{
    
    static addressList = (requestData: any) =>{
        const url = ApiEndpoints.ADDRESS_LIST;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;
    }
}