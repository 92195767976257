import { Link } from "react-router-dom";
import { clear } from "../features/cartSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function ThankYou() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clear());
  });

  return (
    <>
      <title>Order Placed Successfully</title>
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          backgroundColor: "#f4f4f4",
          textAlign: "center",
          padding: "50px 0",
        }}
      >
        <div
          style={{
            maxWidth: "600px",
            margin: "50px auto",
            backgroundColor: "#fff",
            padding: "30px",
            borderRadius: "8px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
        >
          <h2 style={{ color: "#333" }}>Order Placed Successfully</h2>
          <p>
            Thank you for your order. We are processing it and will notify you
            once it's shipped.
          </p>
          <Link
            to="/"
            style={{
              display: "inline-block",
              marginTop: "20px",
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "#fff",
              textDecoration: "none",
              borderRadius: "5px",
              transition: "background-color 0.3s",
            }}
          >
            Back Home
          </Link>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
