import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class SummeryNonLogin{
    
    static summeryNonLogin = (requestData: any) =>{
        const url = ApiEndpoints.CARTSUMMERY_NON_LOGIN;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;
    }
}