import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class PlaceOrderService {
    static placeOrder = async (requestData: any) => {
      const url =  ApiEndpoints.PLACE_ORDER;
    //   {"shippingId":57077392547,"billingId":57077392547,"payMode":"Cod","coupon":""}
      const response = await FetchApi(url, JSON.stringify(requestData)); 
      return typeof response === 'string' ? JSON.parse(response) : response;
    }
  }