import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { LoginService } from '../Services/LoginService';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setCartItems } from '../features/cartSlice';
import { RootState } from '../redux/Store';

import { CartItem } from '../features/cartSlice';
import { WishlistItem } from '../features/wishListSlice';
import { ViewCartService } from '../Services/ViewCartService';
import { setUser } from '../features/userSlice';
const SignInForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cart = useSelector((state: RootState) => state.cart.items);
  const wishlist = useSelector((state: RootState) => state.wishlist.items);

  const [loading, setLoading] = useState(false);

  const initialValues = {
    log_email: '',
    log_password: '',
  };

  const loginSchema = Yup.object().shape({
    log_email: Yup.string()
  .trim()
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format')
  .email('Invalid email address')
  .required('Email is required'),

    log_password: Yup.string().required('Password is required'),
  });

  const { values, errors, handleChange, handleBlur, handleSubmit, touched, resetForm } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const requestData = {
        email: values.log_email,
        password: values.log_password,
        carts: cart.map((item: CartItem) => ({
          inventoryId: item.inventoryId,
          qty: item.qty,
        })),
        wishlists: wishlist.map((item: WishlistItem) => ({
          inventoryId: item.inventoryId,
          qty: 1,
        })),
      };
  
      setLoading(true);
      try {
        const response = await LoginService.login(requestData);
        // console.log(response);
        if (response.statusCode === "1000") {
          //  console.log("Login successful:", response);
          const token = response.data.authorization.token;
          localStorage.setItem("authToken", token);
          const userData = {
            firstName: response.data.user.firstName,
            lastName: response.data.user.lastName,
            email: response.data.user.email,
          };
          dispatch(setUser(userData));
          localStorage.setItem("userData", JSON.stringify(userData));
  
          // Check if there's a redirect path
          const redirectPath = localStorage.getItem("redirectAfterLogin") || "/";
          localStorage.removeItem("redirectAfterLogin"); // Remove it after use
  
          toast.success("Login successful!", {
            autoClose: 200,
            onClose: async () => {
              resetForm();
              navigate(redirectPath); // Navigate to checkout or home
              await loadViewCart();
            },
          });
        } else {
          toast.error("Invalid credentials. Please try again.", {
            autoClose: 1000,
            onClose: () => {
              resetForm();
            },
          });
         
        }
      } catch (error) {
        toast.error("An error occurred. Please try again later.");
        console.error("Login error:", error);
      } finally {
        setLoading(false);
      }
    },
  });
  
  const loadViewCart = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        return;
      }

      const response = await ViewCartService.viewCart("");
      const parsedData = JSON.parse(response);

      // console.log("Parsed Cart Response:", parsedData);

      if (parsedData && parsedData.data && Array.isArray(parsedData.data.cart) && parsedData.data.cart.length > 0) {
        // console.log("Cart items:", parsedData.data.cart);
        const cartItems = parsedData.data.cart.map((item: any) => ({
          inventoryId: item.inventoryId,
          inventoryName: item.description,
          salePrice: parseFloat(item.price),
          qty: item.qty,
          price: parseFloat(item.price),
          totalPrice: parseFloat((parseFloat(item.total).toFixed(2))),
          inventoryImages: item.productInventory.inventoryImages.map((img: any) => img.image),
          cartId: item.cartId,
          Inventory: item.inventory,
          negative_inv: item.negative_inv
        }));
        dispatch(setCartItems(cartItems));
      }
    } catch (error) {
      // console.error('Error loading cart data:', error);
      toast.error('Error loading cart data.');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      loadViewCart();
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="breadcrumb">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="list-inline list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">Login</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="body-content">
        <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            className="sign-in-page-login mx-auto mb-5"
            style={{
              boxShadow: '0 2px 4px 0 rgba(0,0,0,.08)',
              padding: 20,
              overflow: 'hidden',
              backgroundColor: 'white',
            }}
          >
            
                <h4>Sign in</h4>
                <p>Hello, Welcome to your account.</p>
                <form className="register-form outer-top-xs" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="info-title" htmlFor="log_email">
                      Email Address <span>*</span>
                    </label>
                    <input
                      type="email"
                      name="log_email"
                      className={`form-control unicase-form-control text-input ${errors.log_email && touched.log_email ? 'is-invalid' : ''
                        }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.log_email}
                    />
                    {errors.log_email && touched.log_email && (
                      <div className="invalid-feedback">{errors.log_email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="info-title" htmlFor="log_password">
                      Password <span>*</span>
                    </label>
                    <input
                      type="password"
                      name="log_password"
                      className={`form-control unicase-form-control text-input ${errors.log_password && touched.log_password ? 'is-invalid' : ''
                        }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.log_password}
                    />
                    {errors.log_password && touched.log_password && (
                      <div className="invalid-feedback">{errors.log_password}</div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn-upper btn btn-primary checkout-page-button col-md-12 col-sm-12"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      'Login'
                    )}
                  </button>
                  <div className="spacing text-left">
                    <span className="forgot-password" style={{ color: 'unset' }}>
                      Forgot Password?
                    </span>
                    &nbsp;&nbsp; <Link to="/forget-password"> Reset Now</Link>
                    <br />
                    <span className="forgot-password" style={{ color: 'unset' }}>
                      Don't have an account?
                    </span>
                    &nbsp;&nbsp; <Link to="/register">Register Now</Link>
                    <br />
                  </div>
                </form>
              </div>
            </div>
          </div>
       
    </>
  );
};

export default SignInForm;
