export class AddressModel {
    address: string;
    mobile: string;
    country: string;
    state: string;
    city: string;
    pinCode: string;
    addressId: string;
    countryId: string;
    stateId:string;

    constructor() {
        this.address = "";
        this.mobile = ""; 
        this.country = "";
        this.state = "";
        this.city = "";
        this.pinCode = "";
        this.addressId = "";
        this.countryId='';
        this.stateId='';
    }
}