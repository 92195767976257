import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

// Define types for user
type User = {
  firstName: string;
  lastName: string;
  email: string;
};

type UserContextType = {
  user: User | null;
  login: (userData: User) => void;

  setUser: React.Dispatch<React.SetStateAction<User | null>>;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);

  // Load user data from localStorage when the app first loads
  useEffect(() => {
    const savedUser = localStorage.getItem("userData");

    if (savedUser) {
      try {
        const parsedUser = JSON.parse(savedUser);
        if (parsedUser) {
          setUser(parsedUser);
        }
      } catch (error) {
        console.error("Error parsing localStorage data:", error);
      }
    }
  }, []);

  // Login function
  const login = (userData: User) => {
    setUser(userData); // Set the user state
    localStorage.setItem("userData", JSON.stringify(userData)); // Store user data in localStorage

    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      localStorage.setItem("authToken", authToken); // Store the token as needed
    }
  };

  return (
    <UserContext.Provider value={{ user, login, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
