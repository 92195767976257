import { RootState } from '../redux/Store';

export const selectTotalAmount = (state: RootState) => {
  const total = state.cart.items.reduce((sum, item) => {
    return sum + Number(item.totalPrice);
  }, 0);
  return total;
};








export const selectTotalQuantity = (state: RootState) => {
  return state.cart.items.reduce((total, item) => total + item.qty, 0); 
};

export const selectCartItems = (state: RootState) => state.cart.items;

