import "./index.css";
import "react-tooltip/dist/react-tooltip.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Category from "./pages/Category";
import SignInForm from "./pages/SignInForm";
import RegisterForm from "./pages/RegisterForm";
import EmptyCart from "./pages/EmptyCart";
import WishList from "./pages/WishList";
import Checkout from "./pages/Checkout";
import ProductDetail from "./pages/ProductDetail";
import UserAccount from "./pages/UserAccount";
import Checkout2 from "./pages/Checkout2";
import ThankYou from "./pages/ThankYou";
import EmptyProducts from "./pages/EmptyProducts";
import Invoice from "./pages/Invoice";
import PaymentPending from "./pages/PaymentPending";
import Cart from "./pages/Cart";
import Home2 from "./pages/Home2";
import UserOrder from "./pages/UserOrder";
import UserProfile from "./pages/UserProfile";
import UserChangePassword from "./pages/UserChangePassword";
import UserAddress from "./pages/UserAddress";
import ResetPassword from "./pages/ResetPassword";

const App = () => {
  return (

    <Routes>
      <Route path="/" element={<Layout />}>
        {/* <Route path="" element={<Home2 />} /> */}
        <Route path="" element={<Home />} />
        <Route path="/category/:categoryName" element={<Category />} />
        <Route path="/signinform" element={<SignInForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/emptycart" element={<EmptyCart />} />
        <Route path="/noProductFound" element={<EmptyProducts />} />
        <Route path="/wishlist" element={<WishList />} />
        <Route path="/productDetail/:slug" element={<ProductDetail />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkout2" element={<Checkout2 />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="forget-password" element={<ResetPassword />} />
        <Route path="/paymentPending" element={<PaymentPending />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="customer" element={<UserAccount />}>
          {/* Redirect /customer to /customer/orders */}
          <Route index element={<Navigate to="orders" />} />
          <Route path="orders" element={<UserOrder />} />
          <Route path="wishlist" element={<WishList />} />
          <Route path="profile" element={<UserProfile />} />
          <Route path="password-change" element={<UserChangePassword />} />
          <Route path="address" element={<UserAddress />} />
         
        </Route>
      </Route>
    </Routes>

  );
};

export default App;


