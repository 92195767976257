import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { UserProvider } from "../Contexts/UserContext";
import { Provider } from "react-redux";
import { store, persistor } from '../redux/Store';
import { PersistGate } from 'redux-persist/integration/react';

import { useEffect, useState } from "react";
import { PulseLoader } from 'react-spinners';

const Layout = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <UserProvider>
          <div>
            <body className={scroll ? "sticky-header cnt-home" : "cnt-home"}>
              <Header />
              <Suspense >
                <Outlet />
              </Suspense>
              <Footer />
            </body>
          </div>
        </UserProvider>
      </PersistGate>
    </Provider>
  );
};

export default Layout;
