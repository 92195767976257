import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { viewOrderService } from "../Services/ViewOrderService";
import { OrderDetailModel } from "../model/OrderDetailModel";
import { OrderModel } from "../model/OrderModel";
import { constant } from "../Constants/constant";
import UserOrder from './UserOrder';
import { OrderStatusService } from "../Services/OrderStatusService";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

type OrderDetailProps = {
  orderId: string;
};

const OrderDetail = ({ orderId }: OrderDetailProps) => {
  const [order, setOrder] = useState<OrderModel | null>(null);
  const [orderDetails, setOrderDetails] = useState<OrderDetailModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDetail, setSelectedDetail] = useState<OrderDetailModel | null>(null);
  const [reason, setReason] = useState<string>(''); // 
  const [reasonDetail, setReasonDetail] = useState<string>('');
  const [actionType, setActionType] = useState<string>('');

  const [viewOrderDetail, setViewOrderDetail] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (orderId) {
      viewOrder(orderId);
    }
  }, [orderId]);

  const viewOrder = async (orderId: string) => {
    const requestData = { orderId };
    try {
      const response = await viewOrderService.viewOrder(requestData);
      const parsedData = JSON.parse(response);
      // console.log("single order response:", parsedData);
      setOrder(parsedData.data);
      setOrderDetails(parsedData.data.orderDetails);
      setLoading(false);
    } catch (error) {
      setError("Failed to load order details. Please try again later.");
      setLoading(false);
    }
  };

  const handleActionButtonClick = (detail: OrderDetailModel, type: string) => {
    setActionType(type);
    setReason('');
    setReasonDetail('');
    setSelectedDetail(detail);
  };
  const handleSubmitAction = async () => {
    if (!reason || !reasonDetail) {
      toast.info("Please provide a reason and details.");
      return;
    }
  
    if ((actionType === 'Cancel' || actionType === 'Return') && selectedDetail) {
      const requestData = {
        orderDetailId: selectedDetail.orderDetailId,
        reason: reason,
        details: reasonDetail,
        status: actionType === 'Cancel' ? "Cancelled" : "Returned",
        inventoryId: selectedDetail.productInventory.inventoryId,
        qty: selectedDetail.qty
      };
  
      try {
        const response = await OrderStatusService.orderStatus(requestData);
        const parsedData = JSON.parse(response);
  
        if (parsedData.statusCode === '1000') {
          ($("#actionModal") as any).modal("hide");
          setTimeout(() => {
            Swal.fire({
              title: `${actionType} Successful`,
              text: parsedData.statusRemark,
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              viewOrder(orderId);
            });
          }, 100);
  
        } else {
          alert(`Failed to ${actionType} order: ${parsedData.statusRemark}`);
        }
      } catch (error) {
        alert(`Failed to ${actionType} order item. Please try again.`);
      }
    }
  };

  const handleBackButtonClick = () => {
    setViewOrderDetail(false);
  };

  const handleViewInvoice = (orderId: string) => {
    // console.log("Navigating to invoice  with orderId: ", orderId);
    navigate("/invoice", { state: { orderId } });
  };

  if (!viewOrderDetail) {
    return <UserOrder />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <button className="btn btn-primary p-2 m-1 text-white" onClick={handleBackButtonClick}>
          Back to Orders
        </button>

        {order?.invoiceNo && (
          <button
            className="btn btn-warning p-2 m-1 text-white"
            onClick={() => handleViewInvoice(orderId)}
          >
            View Invoice
          </button>
        )}
      </div>

      <div className="row mt-3">
        <div className="col-sm-4">
          <p><strong>Order Id:</strong> {order?.orderId}</p>
          <p><strong>Order Date:</strong> {order?.orderDate}</p>
          <p><strong>Order Value:</strong> ₹ {order?.total}</p>
          <p><strong>Discount:</strong> ₹ {order?.discountValue}</p>
          <p><strong>Order Type:</strong> {order?.orderType}</p>
          <p><strong>Invoice Id:</strong> {order?.invoiceNo}</p>
        </div>

        <div className="col-sm-4">
          <p><strong>Billing Address:</strong></p>
          <p><i className="fa fa-user" /> {order?.customerName}</p>
          <p><i className="fa fa-phone" /> {order?.customerPhone}</p>
          <p><i className="fa fa-address-card-o" /> {order?.customerAddress}</p>
        </div>

        <div className="col-sm-4">
          <p><strong>Shipping Address:</strong></p>
          {order?.sameShipped === 1 ? (
            <>
              <p><i className="fa fa-user" /> {order?.customerName}</p>
              <p><i className="fa fa-phone" /> {order?.customerPhone}</p>
              <p><i className="fa fa-address-card" /> {order?.customerAddress}</p>
            </>
          ) : (
            order?.shippedAddress && (
              <>
                <p><i className="fa fa-user" /> {order?.shippedAddress.name}</p>
                <p><i className="fa fa-phone" /> {order?.shippedAddress.phone}</p>
                <p><i className="fa fa-address-card" /> {order?.shippedAddress.address}, {order?.shippedAddress.city}, {order?.shippedAddress.state}, {order?.shippedAddress.country}</p>
              </>
            )
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <table className="table table-striped w-100">
            <tbody>
              {orderDetails.map((detail) => (
                <tr key={detail.orderDetailId}>
                  <td colSpan={4}>
                    {detail.productInventory.inventoryImages[0]?.image && (
                      <img
                        width={60}
                        src={`${constant.IMAGE_API_URL}${detail.productInventory.inventoryImages[0]?.image}`}
                        alt="Product Image"
                      />
                    )}
                  </td>
                  <td className="text-left order-detail-sec">
                    <p>{detail.productInventory?.inventoryName}</p>
                    <p><strong>Amount: </strong>₹ {detail.total}</p>
                    <p><strong>Quantity: </strong>{detail.qty}</p>
                    <p><strong>Order Status: </strong><span className="text-success">{detail.sts}</span></p>
                  </td>
                  <td>
                    {detail.sts === 'Placed' && (
                      <button
                        className="btn btn-danger m-1 p-2"
                        title="Cancel"
                        data-toggle="modal"
                        data-target="#actionModal"
                        onClick={() => handleActionButtonClick(detail, 'Cancel')}
                      >
                        Cancel
                      </button>
                    )}
                    {detail.sts === 'Delivered' && (
                      <button
                        className="btn btn-warning"
                        title="Return"
                        data-toggle="modal"
                        data-target="#actionModal"
                        onClick={() => handleActionButtonClick(detail, 'Return')}
                      >
                        Return
                      </button>
                    )}
                    {!['Placed', 'Delivered'].includes(detail.sts) && (
                      <p className="text-danger"> {detail.reason}</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      <div className="modal fade" id="actionModal" tabIndex={-1} aria-labelledby="actionModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="actionModalLabel">{actionType} Order</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <label htmlFor="reason">Reason for {actionType}</label>
              <input
                id="reason"
                className="form-control"
                placeholder="Enter reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <label htmlFor="reasonDetail">Reason Detail</label>
              <textarea
                id="reasonDetail"
                className="form-control"
                placeholder="Enter details"
                value={reasonDetail}
                onChange={(e) => setReasonDetail(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={handleSubmitAction}>
                Submit {actionType}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
