import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";


export class OrderStatusService{
    static orderStatus = (requestData: any) =>{
        const url = ApiEndpoints.ORDER_STATUS;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;
    }
}