 export const constant = {
KEY: 'ACC62A67A3D055E6AF68BD9D3ED9A519',
IV:'608538a015674f17',

 // production
  IMAGE_API_URL: "https://my.nipunbycsg.com/media/"

// IMAGE_API_URL: 'http://192.168.0.18:9091/media/',
 

 // local
//  IMAGE_API_URL: 'http://192.168.0.10:8081/media/',

}