import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class CouponService{
    
    static coupon = (requestData: any) =>{
        const url = ApiEndpoints.APPLY_COUPON;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;
    }
}