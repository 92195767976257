import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class GetCountryService{
    
    static getCountry = (requestData: any) =>{
        const url = ApiEndpoints.GET_COUNTRY;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;

        
    }
}