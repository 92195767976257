import axios from "axios";
import { Auth } from "../Auth/Auth";
import { ApiUrl } from "../Constants/ApiUrl";

export const FetchApi = async (url: string, requestJsonStr: string | FormData) => {
  try {
    const requestUrl = ApiUrl.baseUrl + url;
    let requestObj = null;

    const token = localStorage.getItem('authToken');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    if (!(requestJsonStr instanceof FormData)) {
      if (requestJsonStr.length > 0) {
        const encrypteddata = await Auth.getEncryptText(requestJsonStr);
        requestObj = { data: encrypteddata };
      }
    } else {
      requestObj = requestJsonStr;
    }

    //  console.log("Request Object-", requestObj); 

    const encryptRes = await axios.post<string>(requestUrl, requestObj, {
      responseType: 'text' as 'json',
      headers: headers,
    });

    // console.log("Encrypted res", encryptRes);
    const decryptdata = await Auth.getDecryptText(JSON.parse(encryptRes.data));
    
    // console.log("Decrypted data"+decryptdata); 
    return decryptdata;
    
    // const parsedDecryptedData = JSON.parse(decryptdata);
    // console.log("Parsed Decrypted Data: ", parsedDecryptedData);
    // return parsedDecryptedData;

  } catch (error) {
    // console.error(error);
    throw error;
  }
};
