import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { ResetPasswordService } from "../Services/ResetPasswordService";
import { toast, ToastContainer } from "react-toastify";

interface FormValues {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

function UserChangePassword() {
  const [isEditable, setIsEditable] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  const formik = useFormik<FormValues>({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current Password is required"),
      password: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
          .matches(/[a-z]/, "Password must contain at least one lowercase letter")
          .matches(/\d/, "Password must contain at least one number")
          .matches(/[@$!%*?&]/, "Password must contain at least one special character (@, $, !, %, *, ?, &)")
          .required("Please Enter Your Password"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      try {
        const requestData = {
          currentPassword: values.currentPassword,
          password: values.password,
          confirmPassword: values.confirmPassword,
        };
    
        const response = await ResetPasswordService.resetPassword(requestData);
        const parsedResponse = JSON.parse(response);
        // console.log(parsedResponse);
    
        if (parsedResponse.statusCode === "1000") {
          toast.success(parsedResponse.statusRemark , {
            autoClose: 1000,
            onClose: () => {
              formik.resetForm();
              setApiError(null);
              setIsEditable(false); 
            },
          });
        } else if (parsedResponse.statusCode === "0000") {
          toast.error(parsedResponse.statusRemark || "Failed to reset password.", {
            autoClose: 2000,
          });
        }
      } catch (error) {
        toast.error("An error occurred. Please try again later.", { autoClose: 2000 });
      }
    },
    
  });

  // Enable form editing
  const handleEditClick = () => {
    setIsEditable(true);
  };

  // Cancel editing and reset form
  const handleCancelClick = () => {
    setIsEditable(false);
    formik.resetForm();
    setApiError(null);
  };

  return (
    <>
    <ToastContainer />
    <form onSubmit={formik.handleSubmit} id="change_password" autoComplete="off">
      <div className="block-space">
        {/* Current Password */}
        <div className="row">
          <div className="col-md-12 col-sm-6">
            <div className="form-group">
              <label className="info-title" htmlFor="currentPassword">
                Current Password<span>*</span>
              </label>
              <input
                type="password"
                name="currentPassword"
                value={formik.values.currentPassword}
                className="form-control unicase-form-control text-input"
                id="current_password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!isEditable}
                style={{
                  backgroundColor: !isEditable ? "#f0f0f0" : "white",
                  color: !isEditable ? "#777" : "black",
                  border: "1px solid #ccc",
                  cursor: !isEditable ? "not-allowed" : "text",
                }}
              />
              {formik.touched.currentPassword && formik.errors.currentPassword && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.currentPassword}
                </div>
              )}
            </div>
          </div>
      {/* <ToastContainer /> */}
    
  
        {/* New Password */}
        <div className="row">
          <div className="col-md-12 col-sm-6">
            <div className="form-group">
              <label className="info-title" htmlFor="password">
                New Password<span>*</span>
              </label>
              <input
                type="password"
                name="password"
                value={formik.values.password}
                className="form-control unicase-form-control text-input"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!isEditable}
                style={{
                  backgroundColor: !isEditable ? "#f0f0f0" : "white",
                  color: !isEditable ? "#777" : "black",
                  border: "1px solid #ccc",
                  cursor: !isEditable ? "not-allowed" : "text",
                }}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.password}
                </div>
              )}
            </div>
          </div>
        </div>
  
        {/* Confirm Password */}
        <div className="row">
          <div className="col-md-12 col-sm-6">
            <div className="form-group">
              <label className="info-title" htmlFor="confirmPassword">
                Confirm Password<span>*</span>
              </label>
              <input
                type="password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                className="form-control unicase-form-control text-input"
                id="confirm_password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!isEditable}
                style={{
                  backgroundColor: !isEditable ? "#f0f0f0" : "white",
                  color: !isEditable ? "#777" : "black",
                  border: "1px solid #ccc",
                  cursor: !isEditable ? "not-allowed" : "text",
                }}
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.confirmPassword}
                </div>
              )}
            </div>
          </div>
        </div>
  
        {/* Buttons */}
        <div className="d-flex justify-content-end mt-3">
          {!isEditable ? (
            <button type="button" className="btn btn-primary" onClick={handleEditClick}>
              Edit
            </button>
          ) : (
            <>
              <button type="button" className="btn btn-danger mx-2" onClick={handleCancelClick}>
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </>
          )}
        </div>
      </div>
      </div>
    </form>
  </>
  
  );
}

export default UserChangePassword;
