import React, { useState, useEffect, useMemo, Suspense } from "react";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import StarRatings from "react-star-ratings";
import { Auth } from "../Auth/Auth";
import { Link } from "react-router-dom";
import "./customOwlCarousel.css";
import { SubCategoriesModel } from "../model/SubCategoriesModel";
import { addToCartService } from "../Services/AddToCartService";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { constant } from "../Constants/constant";
import { addtoWishListService } from "../Services/AddWishListService";
import { add } from '../features/cartSlice';
import { useDispatch } from "react-redux";
import { CartItem } from '../features/cartSlice';
import { ProductInventoriesModel } from "../model/ProductInventoriesModel";
import { useSelector } from "react-redux";
import { RootState } from '../redux/Store';
import { PulseLoader } from 'react-spinners';
import { IndexService } from "../Services/IndexService";
const BannerCarousel = React.lazy(() => import('./BannerCarousel'));

type Products = {
  categoryId: string;
  categoryName: string;
  subCategories: SubCategoriesModel[];
};

const Home = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Cart = useSelector((state: RootState) => state.cart.items);
  const wishlist = useSelector((state: RootState) => state.wishlist.items);
  const [responseData, setResponseData] = useState<Products[]>([]);
  const [filters, setFilters] = useState<{ [key: string]: string | number | null }>({});
  const [banners, setBanners] = useState<any[]>([]);
  const [carouselKey, setCarouselKey] = useState(0);
  const [isLoadingBanners, setIsLoadingBanners] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  const cartItems = useSelector((state: RootState) => state.cart.items);
  const handleAddToCart = async (product: ProductInventoriesModel) => {
    // console.log("Available qty:", product.qty);  // Logging the available quantity

    const cartItem: CartItem = {
      inventoryId: product.inventoryId,
      inventoryName: product.inventoryName,
      salePrice: product.salePrice,
      mrp: product.mrp,
      qty: 1,
      inventoryImages: product.inventoryImages.map(image => image.image),
      totalPrice: product.salePrice * 1,
      cartId: 0,
      slug: product.slug,
      inventory: product.inventory,
      negative_inv: product.negative_inv
    };

    const authToken = localStorage.getItem("authToken");

    if (!authToken) {
      // Non-logged-in users
      if (product.negative_inv === 1 && product.inventory === 1) {
        // Case 1: Inventory is 1, negative_inv is 1 - Add to cart directly without checking available qty
        const itemExists = cartItems.some(item => item.inventoryId === cartItem.inventoryId);
        if (itemExists) {
          toast.info("This product is already in your cart!", { autoClose: 1000 });
        } else {
          dispatch(add(cartItem));  // Adding item to Redux
        }
      } else if (product.negative_inv === 0 && product.inventory === 1) {
        // Case 2: Inventory is 1, negative_inv is 0 - Check available qty
        if (product.qty < cartItem.qty) {
          toast.error("Available stock is less than requested.", { autoClose: 2000 });
        } else {
          const itemExists = cartItems.some(item => item.inventoryId === cartItem.inventoryId);
          if (itemExists) {
            toast.info("This product is already in your cart!", { autoClose: 1000 });
          } else {
            dispatch(add(cartItem));  // Adding item to Redux
          }
        }
      } else if (product.inventory === 0 && product.negative_inv === 0) {
        // Case 3: Inventory is 0, negative_inv is 0 - Add to cart directly without checking available qty
        const itemExists = cartItems.some(item => item.inventoryId === cartItem.inventoryId);
        if (itemExists) {
          toast.info("This product is already in your cart!", { autoClose: 1000 });
        } else {
          dispatch(add(cartItem));  // Adding item to Redux
        }
      } else {

        toast.error("Unable to add this product to the cart.", { autoClose: 2000 });
      }
    } else {
      // Case for logged-in users
      try {
        const requestData = {
          inventoryId: product.inventoryId,
          qty: 1,
          cartId: "",
        };

        const response = await addToCartService.addtocart(requestData);
        let parsedResponse = typeof response === "string" ? JSON.parse(response) : response;

        if (parsedResponse.statusCode === '1000' && parsedResponse.statusMsg === 'SUCCESS') {
          const apiCartItem: CartItem = {
            inventoryId: parsedResponse.data.inventoryId,
            inventoryName: parsedResponse.data.description,
            salePrice: parsedResponse.data.price,
            mrp: parsedResponse.data.mrp,
            qty: parsedResponse.data.qty,
            inventoryImages: product.inventoryImages.map(image => image.image),
            totalPrice: parsedResponse.data.total,
            cartId: parsedResponse.data.cartId,
            slug: parsedResponse.data.slug,
            inventory: parsedResponse.data.inventory,
            negative_inv: parsedResponse.data.negative_inv
          };

          dispatch(add(apiCartItem));
        } else if (parsedResponse.statusCode === "0000" && parsedResponse.statusMsg === "ERROR") {
          if (parsedResponse.statusRemark.includes("This product is already added to the cart")) {
            toast.info("This product is already in your cart!", { autoClose: 500 });
          }
        }
      } catch (error) {
        toast.error("Error occurred while adding to cart", { autoClose: 500 });
      }
    }
  };



  // product add to wishlist
  const handleAddToWishList = async (id: number) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      toast.error('Please login to add products to the wishlist', {
        autoClose: 1000,
        onClose: () => {
          navigate('/SignInForm');
        }
      });
      return;
    }

    const requestData = { inventoryId: id };
    try {
      const response = await addtoWishListService.addToWishList(requestData);
      let parsedResponse;
      if (typeof response === "string") {
        parsedResponse = JSON.parse(response);
      } else {
        parsedResponse = response;
      }
      if (parsedResponse.statusCode === '1000' && parsedResponse.statusMsg === 'SUCCESS') {
        toast.success("Product added to wishlist successfully!", { autoClose: 1000 });
      } else if (parsedResponse.statusCode === "0000" && parsedResponse.statusMsg === "ERROR") {
        if (parsedResponse.statusRemark.includes("This product is already added to the wishlist")) {
          toast.info("This product is already in your wishlist!", { autoClose: 1000 });
        } else {
          toast.error("Error adding product to wishlist", { autoClose: 1000 });
        }
      }
    } catch (error) {
      toast.error("Error adding product to wishlist", { autoClose: 1000 });
    }
  };

  const handleFilter = (categoryId: string | number, subCategoryId: string | number | "all") => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [categoryId]: subCategoryId === "all" ? null : subCategoryId
    }));
    setCarouselKey(prevKey => prevKey + 1);
  };
  useEffect(() => {
    const currentTime = new Date().getTime();
    const lastFetchTime = localStorage.getItem('bannerFetchTime');
    const lastFetchTimeNum = lastFetchTime ? parseInt(lastFetchTime, 10) : 0;
    const fiveDaysInMilliseconds = 5 * 24 * 60 * 60 * 1000;
    if (!lastFetchTime || currentTime - lastFetchTimeNum > fiveDaysInMilliseconds) {
      localStorage.setItem('bannerFetchTime', currentTime.toString());
      fetchInfo();
    } else {
      const storedBanners = localStorage.getItem('banners');
      if (storedBanners) {
        setBanners(JSON.parse(storedBanners));
        setIsLoadingBanners(false);
      } else {
        setIsLoadingBanners(true);
        fetchInfo();
      }
    }
  }, []);

  const fetchInfo = async () => {
    try {
      const requestData = "";
      const response = await IndexService.index(requestData);
      const responseData = JSON.parse(response);
      // console.log(responseData);
      setResponseData(responseData.data.products);
      setIsLoadingProducts(false);
      setBanners(responseData.data.banner);
      localStorage.setItem('banners', JSON.stringify(responseData.data.banner));
      setIsLoadingBanners(false);
    } catch (error) {
      console.error(error);
      setIsLoadingBanners(false);
      setIsLoadingProducts(false);
    }
  };

  // Fetch API data
  useEffect(() => {
    fetchInfo();
  }, []);

  const filteredCategories = useMemo(() => {
    return responseData.map((category) => {
      const selectedSubCategory = filters[category.categoryId];
      const filteredSubCategories = category.subCategories.map((subCategory) => {
        if (!selectedSubCategory || subCategory.subCategoryId === selectedSubCategory || selectedSubCategory === "all") {
          return { ...subCategory, products: subCategory.products };
        } else {
          return { ...subCategory, products: [] };
        }
      });
      return { ...category, subCategories: filteredSubCategories };
    });
  }, [responseData, filters]);

  const encryptId = (id: any) => {
    return btoa(id);
  }

  return (
    <>
      <ToastContainer />
      <div className="body-content outer-top-xs" id="top-banner-and-menu">
        <div className="container-fluid">
          <Suspense fallback={<div>Loading banners...</div>}>
            <BannerCarousel
              isLoadingBanners={isLoadingBanners}
              banners={banners}
              constant={constant}
            />
          </Suspense>


          {isLoadingProducts ? (
            <div className="loader-container">
              <PulseLoader color="#0f6cb2" loading={isLoadingProducts} size={12} />
              <span>Loading Products...</span>
            </div>
          ) : (
            filteredCategories.map((category) => (
              <div className="row" key={category.categoryId}>
                <div id="product-tabs-slider" className="scroll-tabs outer-top-vs wow fadeInUp">
                  <div className="more-info-tab clearfix">
                    <h3 className="new-product-title pull-left">{category.categoryName}</h3>
                    <ul className="nav nav-tabs nav-tab-line pull-right" id="new-products-1">
                      <li className={filters[category.categoryId] === null ? "active" : ""}>
                        <a href="javascript:void(0)" onClick={() => handleFilter(category.categoryId, "all")}>All</a>
                      </li>
                      {category.subCategories.map((subCategory) => (
                        <li key={subCategory.subCategoryId} className={filters[category.categoryId] === subCategory.subCategoryId ? "active" : ""}>
                          <a href="javascript:void(0)" onClick={() => handleFilter(category.categoryId, subCategory.subCategoryId)}>{subCategory.subCategoryName}</a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* <OwlCarousel
                    className="owl-carousel best-seller custom-carousel owl-theme outer-top-xs"
                    key={carouselKey}
                    nav={true}
                    navText={[
                      "<i className='fa fa-arrow-left banner-left-arrow'></i>",
                      "<i className='fa fa-arrow-right banner-right-arrow'></i>"
                    ]}
                    items={4}
                    dots={false}
                    responsive={{
                      0: { items: 1 },
                      600: { items: 3 },
                      1000: { items: 4 }
                    }}
                    style={{ width: '100%' }}
                  > */}
                  <OwlCarousel
                    className="owl-carousel best-seller custom-carousel owl-theme outer-top-xs"
                    nav={true}
                    navText={[
                      "<i className='fa fa-arrow-left banner-left-arrow'></i>",
                      "<i className='fa fa-arrow-right banner-right-arrow'></i>"
                    ]}
                    items={4}
                    dots={false}
                    responsive={{
                      0: { items: 1 },
                      600: { items: 3 },
                      1000: { items: 4 }
                    }}
                    style={{ width: '100%' }}
                  >

                    {category.subCategories.flatMap((subCategory) =>
                      subCategory.products.map((product) =>
                        product.productInventories.map((inventories) => (
                          <div className="item item-carousel" key={inventories.inventoryId}>


                            <div className="product">
                              <Link
                                to={`/productDetail/${inventories.slug}?invId=${encryptId(inventories.inventoryId)}`}
                                className="product-link"

                              >
                                <div className="product-image">
                                  <div className="image">
                                  <img
                                  src={
                                    inventories?.inventoryImages?.[0]?.image
                                      ? `${constant.IMAGE_API_URL}${inventories.inventoryImages[0].image}`
                                      : "/assets/images/no-image.png"
                                  }
                                  alt="Product Image"
                                />
                                    <div className="tag new">
                                      <span>new</span>
                                    </div> 
                                  </div>
                                </div>
                                <div className="product-info text-left">
                                    <h3 className="name">{inventories.inventoryName}</h3>
                                    <StarRatings
                                      rating={4}
                                      starRatedColor="gold"
                                      numberOfStars={5}
                                      name="rating"
                                      starDimension="15px"
                                      starSpacing="3px"
                                    />
                                    <div className="product-price">
                                      <span className="price">₹{inventories.salePrice}</span>
                                      <span className="price-before-discount">{inventories.mrp}</span>
                                    </div>
                                </div>
                              </Link>

                              <div className="cart clearfix animate-effect">
                                <div className="action">
                                  <ul className="list-unstyled">
                                    <li className="add-cart-button btn-group">
                                      <button
                                        onClick={() => handleAddToCart(inventories)}
                                        className="btn btn-primary icon"
                                        type="button"
                                        title="Add to Cart"
                                      >
                                        <i className="fa fa-shopping-cart"></i>
                                      </button>
                                    </li>
                                    <li className="lnk wishlist">
                                      <Link
                                        to={"/"}
                                        onClick={() => handleAddToWishList(inventories.inventoryId)}
                                        className="add-to-cart"
                                        title="Wishlist"
                                      >
                                        <i className="icon fa fa-heart"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                          </div>
                        ))
                      )
                    )}
                  </OwlCarousel>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
