import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export type CartItem = {
  inventoryId: number;
  inventoryName: string;
  salePrice: number;
  mrp: number;
  qty: number;
  inventoryImages: string[];
  totalPrice: number;
  cartId: number;
  slug: string;
  inventory: number;
  negative_inv: number;
};

const initialState = {
  items: [] as CartItem[],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<CartItem>) => {
      const newItem = action.payload;
      const existingItem = state.items.find(item => item.inventoryId === newItem.inventoryId);
    
      if (existingItem) {
        toast.info("This product is already in your cart!", { autoClose: 1000 });
      } else {
        const updatedNewItem = {
          ...newItem,
          totalPrice: parseFloat((newItem.salePrice * newItem.qty).toFixed(2)),
          cartId: new Date().getTime(),
        };
        state.items.push(updatedNewItem);
        toast.success("Product added to your cart successfully!", { autoClose: 1000 });
    
      }
    },
    

    remove: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter(item => item.inventoryId !== action.payload);
    },

    clear: (state) => {
      state.items = [];
    },

   increase: (state, action: PayloadAction<number>) => {
  const item = state.items.find(item => item.inventoryId === action.payload);
  if (item) {
    item.qty += 1;
    item.totalPrice = parseFloat((item.salePrice * item.qty).toFixed(2));
  }
},

    
    decrease: (state, action: PayloadAction<number>) => {
      const item = state.items.find(item => item.inventoryId === action.payload);
      if (item && item.qty > 1) {
        item.qty -= 1;
        item.totalPrice = parseFloat((item.salePrice * item.qty).toFixed(2));
        // toast.success("Item quantity decreased.", { autoClose: 500 });
      } else if (item && item.qty === 1) {
        state.items = state.items.filter(i => i.inventoryId !== action.payload);
        toast.info("Item removed from cart as the quantity reached 0.", { autoClose: 1000 });
      }
    },

    setCartItems: (state, action: PayloadAction<CartItem[]>) => {
      state.items = action.payload;
    },

    
   
  },
});

export const { add, remove, clear, increase, decrease, setCartItems, } = cartSlice.actions;
export default cartSlice.reducer;
