import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import { ForgotPasswordService } from "../Services/ForgotPasswordService";
import Swal from 'sweetalert2';

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    email: '',
  };

  const forgotpasswordSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format')
      .email('Invalid email address')
      .required('Email is required'),
  });

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: forgotpasswordSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await ForgotPasswordService.forgotPassword(values);
        const parsedData = JSON.parse(response);
        const statusRemark = parsedData.statusRemark || 'Something went wrong!';

        if (statusRemark.includes('Email not found')) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: statusRemark,
            confirmButtonText: 'OK',
          }).then(() => {
            resetForm(); // Reset form after error
          });
        } 
        else if (parsedData.statusCode === '1000') {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: statusRemark,
            confirmButtonText: 'OK',
          }).then(() => {
            navigate('/signinform'); // Redirect on success
          });
        } 
        else if (parsedData.statusCode === '0000') {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: statusRemark,
            confirmButtonText: 'OK',
          }).then(() => {
            resetForm(); // Reset form on statusCode "0000"
          });
        } 
        else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: statusRemark,
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        console.error('Error sending forgot password request:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again later.',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="breadcrumb">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="list-inline list-unstyled">
              <li><Link to="/">Home</Link></li>
              <li className="active">Login</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="body-content">
        <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            className="sign-in-page-login mx-auto mb-5"
            style={{
              boxShadow: '0 2px 4px 0 rgba(0,0,0,.08)',
              padding: 20,
              overflow: 'hidden',
              backgroundColor: 'white',
            }}
          >
            <h4>Forget Password</h4>
            <form className="register-form outer-top-xs" onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="info-title" htmlFor="email">
                  Email Address <span>*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  className={`form-control unicase-form-control text-input ${errors.email && touched.email ? 'is-invalid' : ''}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>

              <button
                type="submit"
                className="btn-upper btn btn-primary checkout-page-button col-md-12 col-sm-12"
                disabled={loading}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                  'Send'
                )}
              </button>

              <div className="spacing text-left">
                <span className="forgot-password" style={{ color: 'unset' }}>
                  Already Have an Account?
                </span>
                &nbsp;&nbsp; <Link to="/signinform">Login</Link>
                <br />
                <span className="forgot-password" style={{ color: 'unset' }}>
                  Don't have an account?
                </span>
                &nbsp;&nbsp; <Link to="/register">Register Now</Link>
                <br />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
