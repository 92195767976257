import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { productFilterService } from "../Services/ProductFilterService";
import { ProductsModel } from "../model/ProductsModel";
import { ProductInventoriesModel } from "../model/ProductInventoriesModel";
import { InventoryImageModel } from "../model/InventoryImageModel";
import { Link } from "react-router-dom";
import { constant } from "../Constants/constant";
import StarRatings from "react-star-ratings";
import { Parser as HtmlToReactParser } from "html-to-react";
import { useNavigate } from "react-router-dom";
import { addToCartService } from "../Services/AddToCartService";
import { addtoWishListService } from "../Services/AddWishListService";
import { toast, ToastContainer } from "react-toastify";
import EmptyProducts from "./EmptyProducts";
import { add } from '../features/cartSlice';
import { useDispatch } from "react-redux";
import { CartItem } from '../features/cartSlice';
import { useSelector } from "react-redux";
import { RootState } from "../redux/Store";
import { PulseLoader } from "react-spinners";

const Category = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState<ProductsModel[]>([]);
  const [noProducts, setNoProducts] = useState(false);
  const [activeView, setActiveView] = useState<"grid" | "list">("grid");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const categoryId = location.state?.categoryId;
  const subCategoryId = location.state?.subcategoryId;
  const search = location.state?.search;

  const { categoryName: paramCategoryName } = useParams();

  // First, try to get categoryName from location state, otherwise use URL params
  const categoryName = location.state?.categoryName || paramCategoryName || "";

  useEffect(() => {
    fetchFilteredProducts();
    setActiveView("grid");
  }, [categoryId, subCategoryId, search]);

  const fetchFilteredProducts = async () => {
    const requestData = {
      categoryId: categoryId || null,
      subcategoryId: subCategoryId || null,
      search: search || null,
    };

    setIsLoading(true);

    try {
      const response = await productFilterService.productfilter(requestData);
      const parsedData = JSON.parse(response);
      // console.log(parsedData);

      // Ensure we always access the correct key: 'products' or 'product'
      const productsArray = parsedData?.data?.products || parsedData?.data?.product || [];

      if (productsArray.length > 0) {
        const mappedProducts = productsArray.map((product: any) => {
          const productModel = new ProductsModel();
          productModel.productId = product.productId;
          productModel.productName = product.productName;
          productModel.description = product.description;

          // Ensure 'productInventory' is always treated as an array
          const inventories = Array.isArray(product.productInventory)
            ? product.productInventory
            : [product.productInventory];

          productModel.productInventories = inventories.map((inventory: any) => {
            const inventoryModel = new ProductInventoriesModel();
            inventoryModel.inventoryId = inventory.inventoryId;
            inventoryModel.inventoryName = inventory.inventoryName;
            inventoryModel.mrp = inventory.mrp;
            inventoryModel.salePrice = inventory.salePrice;
            inventoryModel.qty = inventory.qty;
            inventoryModel.slug = inventory.slug;
            inventoryModel.inventory = inventory.inventory;
            inventoryModel.negative_inv = inventory.negative_inv;

            // Ensure inventoryImages is always an array
            inventoryModel.inventoryImages = Array.isArray(inventory.inventoryImages)
              ? inventory.inventoryImages.map((image: any) => {
                const imageModel = new InventoryImageModel();
                imageModel.imageId = image.imageId;
                imageModel.image = image.image;
                return imageModel;
              })
              : [];

            return inventoryModel;
          });

          return productModel;
        });

        setResponseData(mappedProducts);
        setNoProducts(false);
      } else {
        setNoProducts(true);
        setResponseData([]);
      }
    } catch (error) {
      console.error("Error fetching filtered products:", error);
      setNoProducts(true);
      setResponseData([]);
    } finally {
      setIsLoading(false);
    }
  };


  const htmlToReactParser = HtmlToReactParser();


  const cartItems = useSelector((state: RootState) => state.cart.items);
  const handleAddToCart = async (product: ProductInventoriesModel) => {
    const cartItem: CartItem = {
      inventoryId: product.inventoryId,
      inventoryName: product.inventoryName,
      salePrice: product.salePrice,
      mrp: product.mrp,
      qty: 1,
      inventoryImages: product.inventoryImages.map(image => image.image),
      totalPrice: product.salePrice * 1,
      cartId: 0,
      slug: product.slug,
      inventory: product.inventory,
      negative_inv: product.negative_inv
    };

    const authToken = localStorage.getItem("authToken");

    if (!authToken) {
      // Case when the user is not logged in
      if (product.inventory === 1 && product.negative_inv === 1) {
        // Case 1: Inventory is 1, negative_inv is 1 - Directly add to cart
        dispatch(add(cartItem));
      } else if (product.inventory === 1 && product.negative_inv === 0) {
        // Case 2: Inventory is 1, negative_inv is 0 - Check available qty
        if (product.qty < cartItem.qty) {
          toast.error("Not enough inventory available.");
        } else {
          dispatch(add(cartItem));
        }
      } else if (product.inventory === 0 && product.negative_inv === 0) {
        dispatch(add(cartItem));
      } else {

        toast.error("Unable to add this product to the cart.");
      }
    } else {
      try {
        const requestData = {
          inventoryId: product.inventoryId,
          qty: 1,
          cartId: "", // Handle cart ID properly here if needed
        };

        const response = await addToCartService.addtocart(requestData);
        let parsedResponse = typeof response === "string" ? JSON.parse(response) : response;

        if (parsedResponse.statusCode === '1000' && parsedResponse.statusMsg === 'SUCCESS') {
          const apiCartItem: CartItem = {
            inventoryId: parsedResponse.data.inventoryId,
            inventoryName: parsedResponse.data.description,
            salePrice: parsedResponse.data.price,
            mrp: parsedResponse.data.mrp,
            qty: parsedResponse.data.qty,
            inventoryImages: product.inventoryImages.map(image => image.image),
            totalPrice: parsedResponse.data.total,
            cartId: parsedResponse.data.cartId,
            slug: parsedResponse.data.slug,
            inventory: parsedResponse.data.inventory,
            negative_inv: parsedResponse.data.negative_inv
          };

          dispatch(add(apiCartItem));
        } else if (parsedResponse.statusCode === "0000" && parsedResponse.statusMsg === "ERROR") {
          if (parsedResponse.statusRemark.includes("This product is already added to the cart")) {
            toast.info("This product is already in your cart!", { autoClose: 500 });
          }
        }
      } catch (error) {
        toast.error("Error occurred while adding to cart", { autoClose: 500 });
      }
    }
  };

  const handleAddToWishList = async (id: number) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      toast.error('Please login to add products to the wishlist', {
        autoClose: 1000,
        onClose: () => {
          navigate('/SignInForm');
        }
      });
      return;
    }

    const requestData = { inventoryId: id };
    try {
      const response = await addtoWishListService.addToWishList(requestData);
      let parsedResponse;
      if (typeof response === "string") {
        parsedResponse = JSON.parse(response);
      } else {
        parsedResponse = response;
      }
      if (parsedResponse.statusCode === '1000' && parsedResponse.statusMsg === 'SUCCESS') {
        toast.success("Product added to wishlist successfully!", { autoClose: 1000 });
      } else if (parsedResponse.statusCode === "0000" && parsedResponse.statusMsg === "ERROR") {
        if (parsedResponse.statusRemark.includes("This product is already added to the wishlist")) {
          toast.info("This product is already in your wishlist!", { autoClose: 1000 });
        } else {
          toast.error("Error adding product to wishlist", { autoClose: 1000 });
        }
      }
    } catch (error) {
      toast.error("Error adding product to wishlist", { autoClose: 1000 });
    }
  };
  const encryptId = (id: any) => {
    return btoa(id);
  }
  const PRODUCTS_PER_PAGE = {
    grid: 30,
    list: 20,
  };

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(1);
  }, [activeView, subCategoryId, categoryId]);

  // Flatten product inventories for pagination
  const allProducts = responseData.flatMap((product) => product.productInventories);

  // Get paginated products for the current page
  const startIndex = (currentPage - 1) * PRODUCTS_PER_PAGE[activeView];
  const endIndex = startIndex + PRODUCTS_PER_PAGE[activeView];
  const paginatedProducts = allProducts.slice(startIndex, endIndex);

  // Total pages calculation
  const totalPages = Math.ceil(allProducts.length / PRODUCTS_PER_PAGE[activeView]);

  const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));




  return (
    <>
      <ToastContainer />
      <div className="breadcrumb">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="list-inline list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Category</li>
              <li className="active">{categoryName}</li>
            </ul>

          </div>
        </div>
      </div>
      <div className="body-content outer-top-xs">
        <div className="container-fluid fluid-one">
          <div className="row">
            <div className="col-md-12 ">
              <div className="clearfix filters-container m-t-10">
                <div className="row">
                  <div className="col col-sm-6 col-md-2">
                    <div className="filter-tabs">
                      {/* <ul
                        id="filter-tabs"
                        className="nav nav-tabs nav-tab-box nav-tab-fa-icon"
                      >
                        <li className="active">
                          <a data-toggle="tab" href="#grid-container">
                            <i className="icon fa fa-th-large"></i>Grid
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#list-container">
                            <i className="icon fa fa-th-list"></i>List
                          </a>
                        </li>
                      </ul> */}
                      <ul id="filter-tabs"
                        className="nav nav-tabs nav-tab-box nav-tab-fa-icon">
                        <li className={activeView === "grid" ? "active" : ""}>
                          <a onClick={() => setActiveView("grid")}>
                            <i className="icon fa fa-th-large"></i> Grid
                          </a>
                        </li>
                        <li className={activeView === "list" ? "active" : ""}>
                          <a onClick={() => setActiveView("list")}>
                            <i className="icon fa fa-th-list"></i> List
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="search-result-container">
                <div className="tab-content category-list">
                  {isLoading ? (
                    <div className="loader-container">
                      <PulseLoader color="#0f6cb2" loading={isLoading} size={12} />
                      <span>Loading Products...</span>
                    </div>
                  ) : noProducts ? (
                    <EmptyProducts />
                  ) : (
                    <>
                      {/* Grid View */}
                      {/* Grid View */}
                      {activeView === "grid" && (
                        <div className="tab-pane active">
                          <div className="category-product">
                            <div className="row">
                              {paginatedProducts.map((inventory) => (
                                <div key={inventory.inventoryId} className="col-sm-6 col-md-2 product-space-2">
                                  <div className="products product-design">
                                    <div className="product product-one" onClick={() => navigate(`/productDetail/${inventory.slug}?invId=${encryptId(inventory.inventoryId)}`)} style={{ cursor: 'pointer' }}>
                                      <div className="product-image">
                                        <div className="image">
                                        <img
                                          src={
                                            inventory.inventoryImages?.length > 0
                                              ? `${constant.IMAGE_API_URL}${inventory.inventoryImages[0].image}`
                                              : "/assets/images/no-image.png"
                                          }
                                          alt={inventory.inventoryName || "Product Image"}
                                          className="img-fluid"
                                        />

                                        </div>
                                        {/* <div className="tag new">
                                          <span>new</span>
                                        </div> */}
                                      </div>
                                      <div className="product-info text-left">
                                        <h3 className="name">
                                          <span>{inventory.inventoryName}</span>
                                        </h3>
                                        <StarRatings
                                          rating={4}
                                          starRatedColor="gold"
                                          numberOfStars={5}
                                          name="rating"
                                          starDimension="15px"
                                          starSpacing="3px"
                                        />
                                        <div className="product-price">
                                          <span className="price">₹{inventory.salePrice}</span>
                                          <span className="price-before-discount">₹{inventory.mrp}</span>
                                        </div>
                                      </div>
                                      <div className="cart clearfix animate-effect" onClick={(e) => e.stopPropagation()}>
                                        <div className="action">
                                          <ul className="list-unstyled">
                                            <li className="add-cart-button btn-group">
                                              <button onClick={(e) => { e.stopPropagation(); handleAddToCart(inventory); }} className="btn btn-primary icon addToCart">
                                                <i className="fa fa-shopping-cart"></i>
                                              </button>
                                              <button onClick={(e) => { e.stopPropagation(); handleAddToCart(inventory); }} className="btn btn-primary cart-btn">
                                                Add to cart
                                              </button>
                                            </li>
                                            <li className="lnk">
                                              <a onClick={(e) => { e.stopPropagation(); handleAddToWishList(inventory.inventoryId); }} className="add-to-cart wishlist">
                                                <i className="icon fa fa-heart"></i>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {/* List View */}
                      {activeView === "list" && (
                        <div className="tab-pane active">
                          <div className="category-product">
                            <div className="category-product-inner">
                              <div className="product-list product border-top-0">
                                {paginatedProducts.map((inventory) => {
                                  // Find the parent product that contains this inventory
                                  const parentProduct = responseData.find((product) =>
                                    product.productInventories.some((inv) => inv.inventoryId === inventory.inventoryId)
                                  );

                                  return (
                                    <div key={inventory.inventoryId} className="row product-list-row">
                                      <div className="col-sm-12 product-space">
                                        <div className="product-item border-bottom">
                                          <div className="product-image col-sm-4">
                                          <div className="image">
                                            <Link to={`/productDetail/${inventory.slug}?invId=${encryptId(inventory.inventoryId)}`}>
                                              <img
                                                src={
                                                  inventory.inventoryImages?.length > 0
                                                    ? `${constant.IMAGE_API_URL}${inventory.inventoryImages[0].image}`
                                                    : "/assets/images/no-image.png"
                                                }
                                                alt={inventory.inventoryName || "Product Image"}
                                                className="img-fluid"
                                              />
                                            </Link>
                                          </div>

                                          </div>
                                          <div className="product-info col-sm-8">
                                            <h3 className="name">
                                              <Link to={`/productDetail/${inventory.slug}?invId=${encryptId(inventory.inventoryId)}`}>
                                                {inventory.inventoryName}
                                              </Link>
                                            </h3>
                                            <StarRatings
                                              rating={4}
                                              starRatedColor="gold"
                                              numberOfStars={5}
                                              name="rating"
                                              starDimension="15px"
                                              starSpacing="3px"
                                            />
                                            <div className="product-price">
                                              <span className="price">₹{inventory.salePrice}</span>
                                              <span className="price-before-discount">₹{inventory.mrp}</span>
                                            </div>
                                            <div className="description m-t-10">
                                              {parentProduct?.description ? htmlToReactParser.parse(parentProduct.description) : "No description available"}
                                            </div>
                                            <div className="cart clearfix animate-effect">
                                              <div className="action">
                                                <ul className="list-unstyled">
                                                  <li className="add-cart-button btn-group">
                                                    <button onClick={() => handleAddToCart(inventory)} className="btn btn-primary icon addToCart">
                                                      <i className="fa fa-shopping-cart"></i>
                                                    </button>
                                                    <button onClick={() => handleAddToCart(inventory)} className="btn btn-primary cart-btn">Add to cart</button>
                                                  </li>
                                                  <li className="lnk">
                                                    <a onClick={() => handleAddToWishList(inventory.inventoryId)} className="add-to-cart wishlist">
                                                      <i className="icon fa fa-heart"></i>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Pagination Controls */}

                      <div className="pagination-container">
                        <button onClick={prevPage} disabled={currentPage === 1} className="btn btn-primary">
                          Previous
                        </button>
                        <span className="current-page">Page {currentPage} of {totalPages}</span>
                        <button onClick={nextPage} disabled={currentPage === totalPages} className="btn btn-primary">
                          Next
                        </button>
                      </div>

                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>


  );
};

export default Category;
