import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit'; 
import cartReducer from '../features/cartSlice';
import wishlistReducer from '../features/wishListSlice';
import { userReducer } from '../features/userSlice'; 
const persistConfig = {
  key: 'root',
  storage, 
  whitelist: ['cart', 'wishlist', 'user'],
};

const rootReducer = combineReducers({
  cart: cartReducer, 
  wishlist: wishlistReducer, 
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedReducer, 
  devTools: true, 
});


const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export { store, persistor };
