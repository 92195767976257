export class RegisterModel {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    password: string;
    confirmPassword: string;

    constructor() {
        this.firstName = "";
        this.lastName = ""; 
        this.email = "";
        this.mobile = "";
        this.password = "";
        this.confirmPassword = "";
    }
}