import { ApiEndpoints } from "../Constants/ApiEndpoints";
import { FetchApi } from "./FetchApi";

export class ViewCartService{
    
    static viewCart = (requestData: any) =>{
        const url = ApiEndpoints.VIEW_CART;
        const response = FetchApi(url,JSON.stringify(requestData));
        return response;
    }
}