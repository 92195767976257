import { constant } from "../Constants/constant";

export class Auth { 
static getEncryptText = async (plaintText: string) => {
    let _key = CryptoJS.enc.Utf8.parse(constant.KEY);
    let _iv = CryptoJS.enc.Utf8.parse(constant.IV);
    let encrypted = CryptoJS.AES.encrypt(
      plaintText, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  static getDecryptText = async (encrypted: string) => {
    // console.log("Encrypted string-",encrypted);
           
    let _key = CryptoJS.enc.Utf8.parse(constant.KEY);
    let _iv = CryptoJS.enc.Utf8.parse(constant.IV);
  
    let decrypted = CryptoJS.AES.decrypt(
      encrypted, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    // console.log("decrypted",decrypted);  for debuging
    
    return decrypted
  }
}
  