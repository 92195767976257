import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clear as clearCart } from "../features/cartSlice";
import UserOrder from "./UserOrder";
import UserProfile from "./UserProfile";
import UserAddress from "./UserAddress";
import UserChangePassword from "./UserChangePassword";
import { clearUser } from "../features/userSlice";
import { clearWishlist } from "../features/wishListSlice";
import WishList from "./WishList";

function UserAccount() {
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState<string>("orders");
  const navigate = useNavigate();

  const renderContent = () => {
    switch (activeSection) {
      case "orders":
        return <UserOrder />;
      case "profile":
        return <UserProfile />;
      case "address":
        return <UserAddress />;
      case "change-password":
        return <UserChangePassword />;
      case "myWishlist":
        return <WishList />;
      default:
        return <UserOrder />;
    }
  };

  // Handle logout functionality
  const handleLogout = () => {
    dispatch(clearCart());
    dispatch(clearWishlist());
    dispatch(clearUser());
    localStorage.removeItem("userData");
    localStorage.removeItem("authToken");

    // Redirect to login page
    navigate("/signinform");
  };

  return (
    <div>
      <ToastContainer />

      {/* Breadcrumb */}
      <div className="breadcrumb">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="list-inline list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li className="active">User Account</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="body-content outer-top-xs" id="top-banner-and-menu">
        <div className="container">
          <div className="row d-flex justify-content-center">
            {/* Sidebar */}
            <div className="col-lg-3 col-md-12">
              <div className="list-group listing">
                <button
                  onClick={() => setActiveSection("orders")}
                  className={`list-group-item list-group-item-action ${activeSection === "orders" ? "active" : ""
                    }`}
                >
                  Orders
                </button>

                <button
                  onClick={() => setActiveSection("myWishlist")}
                  className={`list-group-item list-group-item-action ${activeSection === "myWishlist" ? "active" : ""
                    }`}
                >
                  Wishlist
                </button>

                <button
                  onClick={() => setActiveSection("profile")}
                  className={`list-group-item list-group-item-action ${activeSection === "profile" ? "active" : ""
                    }`}
                >
                  Profile
                </button>
                <button
                  onClick={() => setActiveSection("address")}
                  className={`list-group-item list-group-item-action ${activeSection === "address" ? "active" : ""
                    }`}
                >
                  My Address
                </button>
                <button
                  onClick={() => setActiveSection("change-password")}
                  className={`list-group-item list-group-item-action ${activeSection === "change-password" ? "active" : ""
                    }`}
                >
                  Password Change
                </button>

                <button
                  onClick={handleLogout}
                  className="list-group-item list-group-item-action"
                >
                  LogOut
                </button>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-lg-9">
              <div className="detail-block">{renderContent()}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Optional Branding Section */}
      <div id="brands-carousel" className="logo-slider wow fadeInUp">
        <div className="logo-slider-inner">
          <div
            id="brand-slider"
            className="owl-carousel brand-slider custom-carousel owl-theme"
          >
            <div className="item m-t-15">{/* Branding content here */}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAccount;
