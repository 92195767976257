import { ProductInventoriesModel } from "./ProductInventoriesModel";

export class ProductsModel {
productId: number; 
productInventories: ProductInventoriesModel[];
productName: string;
description: string;


  constructor() {
    this.productId = 0;
    this.productInventories = [];
    this.productName = "";
    this.description ="";

  }
}
