import { useState, useEffect } from "react";
import { ProfileUpdateService } from "../Services/ProfileUpdateService";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../features/userSlice";
import { toast, ToastContainer } from "react-toastify";
import { RootState } from "../redux/Store";
import { useFormik } from "formik";
import * as Yup from "yup";

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => {
    if (isEditing) {
      // Reset form values to the original user state when canceling
      formik.resetForm();
    }
    setIsEditing(!isEditing);
  };


  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(3, "First name must be at least 3 characters")
        .matches(/^[A-Za-z\s]+$/, "First name cannot contain numbers or special characters")
        .required("First name is required"),

      lastName: Yup.string().min(3, "Last name must be at least 3 characters")
        .matches(/^[A-Za-z\s]+$/, "Last name cannot contain numbers or special characters")
        .required("Last name is required"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^[a-zA-Z0-9._%+-]+@gmail\.com$/, "Email must end with @gmail.com without extra characters")
        .required("Email is required"),


    }),
    onSubmit: async (values) => {
      try {
        const response = await ProfileUpdateService.profileUpdate(values);
        //  console.log(response);
        if (response?.statusCode === "1000") {
          toast.success(response?.statusRemark || "Profile updated successfully", { autoClose: 500 });
          dispatch(setUser(values));
          setIsEditing(false);
        } else {
          toast.error(response?.statusRemark || "Failed to update profile. Please try again.", { autoClose: 500 });
          setIsEditing(true);
        }
      } catch (error) {
        toast.error("Failed to update profile. Please try again.", { autoClose: 500 });
        setIsEditing(true);
      }
    }




  });

  useEffect(() => {
    if (user) {
      formik.resetForm({
        values: {
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email || "",
        }
      });
    }
  }, [user]);


  return (
    <>
      <ToastContainer />
      <form id="profile_update" className="block-space" onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-md-12 col-sm-6">
            <div className="form-group">
              <label className="info-title" htmlFor="first_name">
                First Name <span>*</span>
              </label>
              <input
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control unicase-form-control text-input"
                id="first_name"
                readOnly={!isEditing}
                style={{
                  backgroundColor: !isEditing ? "#e9ecef" : "white",
                  cursor: !isEditing ? "not-allowed" : "text",
                }}
              />

              {formik.touched.firstName && formik.errors.firstName && (
                <small className="text-danger text-one">{formik.errors.firstName}</small>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-6">
            <div className="form-group">
              <label className="info-title" htmlFor="last_name">
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control unicase-form-control text-input"
                id="last_name"
                readOnly={!isEditing}
                style={{
                  backgroundColor: !isEditing ? "#e9ecef" : "white",
                  cursor: !isEditing ? "not-allowed" : "text",
                }}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <small className="text-danger text-one">{formik.errors.lastName}</small>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-6">
            <div className="form-group">
              <label className="info-title" htmlFor="email">
                Email <span>*</span>
              </label>
              <input
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control unicase-form-control text-input"
                id="email"
                readOnly={!isEditing}
                style={{
                  backgroundColor: !isEditing ? "#e9ecef" : "white",
                  cursor: !isEditing ? "not-allowed" : "text",
                }}
              />
              {formik.touched.email && formik.errors.email && (
                <small className="text-danger text-one">{formik.errors.email}</small>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary outer-left-xs m-3"
            onClick={toggleEdit}
          >
            {isEditing ? "Cancel" : "Edit"}
          </button>

          {isEditing && (
            <button
              type="submit"
              className="btn btn-primary outer-left-xs m-3"
              disabled={!formik.isValid || !formik.dirty}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default UserProfile;
