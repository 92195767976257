import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state for the user
interface UserState {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}

const initialState: UserState = {
  firstName: null,
  lastName: null,
  email: null,
};

// Create the slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Action to set user data in Redux
    setUser: (state, action: PayloadAction<UserState>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    },
    clearUser: (state) => {
      state.firstName = null;
      state.lastName = null;
      state.email = null;
    },
  },
});

// Export the actions and reducer
export const { setUser, clearUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
