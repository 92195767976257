import { ProductInventoriesModel } from "./ProductInventoriesModel";
export class ViewCartModel {
  inventoryId: number;
  cartId: number;
  description: string;
  price: number;
  qty: number;
  subTotal: number;
  discount: number;
  gst:number;
  discountValue: number;
  total: number;
  productInventory:ProductInventoriesModel;
  saveLater: any[];

  constructor() {
     this. inventoryId = 0;
      this.cartId = 0;
      this.description = "";
      this.price = 0;
      this.qty = 0;
      this.subTotal = 0;
      this.gst =0;
      this.discount = 0;
      this.discountValue = 0;
      this.total = 0;
      this.inventoryId = 0;
    
      this.productInventory = new ProductInventoriesModel() ;
     
      this.saveLater = [];
  }
}
