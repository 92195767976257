import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { RegisterService } from "../Services/RegisterService";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { RegisterModel } from "../model/RegisterModel";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  password: "",
  confirmPassword: ""
};

const registerSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, "First name can only contain letters")
    .min(3, "First name must be at least 3 characters")
    .max(20, "First name must not exceed 20 characters")
    .required("Please Enter Your First Name"),

  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Last name can only contain letters")
    .min(3, "Last name must be at least 3 characters")
    .max(20, "Last name must not exceed 20 characters")
    .required("Please Enter Your Last Name"),

    email: Yup.string()
    .matches(
      /^(?!^\d+@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Enter a valid email address"
    )
    .required("Please Enter Your Email"),
  
  
  
    mobile: Yup.string()
    .required("Please Enter Your Mobile Number")
    .matches(/^\d+$/, "Only numbers are allowed")
    .matches(/^(?:\+91)?[6-9]\d{9}$/, "Enter a valid Indian mobile number (10 digits, starts with 6-9, optional +91)"),
    
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[@$!%*?&]/, "Password must contain at least one special character (@, $, !, %, *, ?, &)")
    .required("Please Enter Your Password"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Please Confirm Your Password"),
});


const prepareData = (values: RegisterModel) => {
  const requestData = new RegisterModel();
  requestData.firstName = values.firstName ? values.firstName : "";
  requestData.lastName = values.lastName ? values.lastName : "";
  requestData.email = values.email ? values.email : "";
  requestData.mobile = values.mobile ? values.mobile : "";
  requestData.password = values.password ? values.password : "";
  requestData.confirmPassword = values.confirmPassword ? values.confirmPassword : "";
  return requestData;
};

const RegisterForm = () => {
  // Define loading state here inside the component
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { values, errors, handleChange, handleBlur, handleSubmit, touched, resetForm } = useFormik({
    initialValues,
    validationSchema: registerSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const requestData = prepareData(values);
    
      try {
        const response = await RegisterService.register(requestData);
        const parsedData = JSON.parse(response); // Keep JSON parsing
    //  console.log(parsedData);
        if (parsedData.statusCode === "1000") {
          toast.success("Registration successful", {
            autoClose: 500,
            onClose: () => {
              resetForm();
              navigate('/signinform');
            }
          });
        } else {
          // Handle API error responses
          toast.error(parsedData.statusRemark, {
            autoClose: 1000,
            onClose: () => {
              resetForm();
            }
          });
        }
      } catch (error) {
        // toast.error("An error occurred. Please try again.", {
        //   autoClose: 1000,
        //   onClose: () => {
            resetForm();
          // }
        ;
        console.error("Error during registration:", error);
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <div>
      <ToastContainer />
      <div className="breadcrumb">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="list-inline list-unstyled">
              <li><Link to="/">Home</Link></li>
              <li className="active">Register</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="body-content">
        <div className="container" style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", marginBottom: "40px" }}>
          <div className="sign-in-page " style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,.08)", padding: "20px", backgroundColor: "white" }}>
            <div className="row">
              <div className="col-lg-12 create-new-account">
                <h4 className="checkout-subtitle">Create a new account</h4>
                <form onSubmit={handleSubmit} className="register-form outer-top-xs">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="info-title" htmlFor="firstName">First Name <span>*</span></label>
                        <input
                          type="text"
                          id="first_name"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control unicase-form-control text-input ${errors.firstName && touched.firstName ? 'is-invalid' : ''}`}
                        />
                        {errors.firstName && touched.firstName ? <div className="invalid-feedback">{errors.firstName}</div> : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="info-title" htmlFor="lastName">Last Name <span>*</span></label>
                        <input
                          type="text"
                          id="last_name"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control unicase-form-control text-input ${errors.lastName && touched.lastName ? 'is-invalid' : ''}`}
                        />
                        {errors.lastName && touched.lastName ? <div className="invalid-feedback">{errors.lastName}</div> : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="info-title" htmlFor="email">Email Address <span>*</span></label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control unicase-form-control text-input ${errors.email && touched.email ? 'is-invalid' : ''}`}
                        />
                        {errors.email && touched.email ? <div className="invalid-feedback">{errors.email}</div> : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="info-title" htmlFor="mobile">Mobile <span>*</span></label>
                        <input
                          type="tel"
                          id="mobile"
                          name="mobile"
                          value={values.mobile}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control unicase-form-control text-input ${errors.mobile && touched.mobile ? 'is-invalid' : ''}`}
                        />
                        {errors.mobile && touched.mobile ? <div className="invalid-feedback">{errors.mobile}</div> : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="info-title" htmlFor="password">Password <span>*</span></label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control unicase-form-control text-input ${errors.password && touched.password ? 'is-invalid' : ''}`} />
                        {errors.password && touched.password ? <div className="invalid-feedback">{errors.password}</div> : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="info-title" htmlFor="confirmPassword">Confirm Password <span>*</span></label>
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control unicase-form-control text-input ${errors.confirmPassword && touched.confirmPassword ? 'is-invalid' : ''}`} />
                        {errors.confirmPassword && touched.confirmPassword ? <div className="invalid-feedback">{errors.confirmPassword}</div> : null}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="col-md-12 btn btn-primary checkout-page-button mt-3"
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      'Sign Up'
                    )}
                  </button>

                  <div className="spacing-two text-left">
                    <span className="forgot-password" style={{ color: 'unset' }}>Already have an account?</span>
                    &nbsp;&nbsp;  <Link to="/signinform">Sign in Now</Link>
                    <br />
                    <span className="forgot-password" style={{ color: 'unset' }}>Forgot Password?</span>
                    &nbsp;&nbsp;<Link to="/forget-password"> Reset Now</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
