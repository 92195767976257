import { useEffect, useState } from "react";
import { InfoModel } from "../model/InfoModel";
import { IndexService } from "../Services/IndexService";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/Store";
function Footer() {
  const [info, setInfo] = useState<InfoModel | null>(null)
  const [address, setAddress] = useState<string | null>(null);
  const user = useSelector((state: RootState) => state.user);
  const isUserLoggedIn = user && user.firstName;
  useEffect(() => {
    FullDataLoad();
  }, []);

  const FullDataLoad = async () => {
    try {
      const requestData = "";
      const response = await IndexService.index(requestData);
      const parsedData = JSON.parse(response);
    //  console.log(parsedData);

      if (parsedData.statusCode === "1000") {

        setInfo(parsedData.data.info);
        setAddress(parsedData.data.address?.address);
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  return (
    <footer id="footer" className="footer color-bg">
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="module-heading">
                <h4 className="module-title">Customer Service</h4>
              </div>
              <div className="module-body">
                <ul className="list-unstyled">
                  <li className="first">
                  <Link to={isUserLoggedIn ? "/customer" : "/signinform"} title="My Account">
                   My Account
                   </Link>


                  </li>
                  <li>
                    <Link to={isUserLoggedIn ? "/customer" : "/signinform"} title="About us">
                      Order History
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="faq">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="/"title="Popular Searches">
                      Specials
                    </Link>
                  </li>
                  <li className="last">
                    <Link to="/" title="Where is my order?">
                      Help Center
                    </Link>
                  </li>
                </ul>
              </div>
              {/* /.module-body */}
            </div>
            {/* /.col */}
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="module-heading">
                <h4 className="module-title">Corporation</h4>
              </div>
              {/* /.module-heading */}
              <div className="module-body">
                <ul className="list-unstyled">
                  <li className="first">
                    <Link to="/" title=" About us">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="Customer Service">
                      Customer Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="Company">
                      Company
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="Investor Relations">
                      Investor Relations
                    </Link>
                  </li>
                  <li className="last">
                    <Link to="/" title="Advanced Search">
                      Advanced Search
                    </Link>
                  </li>
                </ul>
              </div>
              {/* /.module-body */}
            </div>
            {/* /.col */}
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="module-heading">
                <h4 className="module-title">Why Choose Us</h4>
              </div>
              {/* /.module-heading */}
              <div className="module-body">
                <ul className="list-unstyled">
                  <li className="first">
                    <Link to="/" title="Shopping Guide">
                      Shopping Guide
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="Blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="Company">
                      Company
                    </Link>
                  </li>
                  <li>
                    <Link to="/" title="Investor Relations">
                      Investor Relations
                    </Link>
                  </li>
                  <li className=" last">
                    <Link to="/" title="Contact Us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              {/* /.module-body */}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="module-heading">
                <h4 className="module-title">Contact Us</h4>
              </div>
              {/* /.module-heading */}
              <div className="module-body">
                <ul className="toggle-footer" style={{}}>
                  <li className="media">
                    <div className="pull-left">
                      {" "}
                      <span className="icon fa-stack fa-lg">
                        {" "}
                        <i className="fa fa-map-marker fa-stack-1x fa-inverse" />{" "}
                      </span>
                    </div>
                    <div className="media-body">
                    {address}
                    </div>
                  </li>
                  <li className="media">
                    <div className="pull-left">
                      {" "}
                      <span className="icon fa-stack fa-lg">

                        <i className="fa fa-mobile fa-stack-1x fa-inverse" />{" "}
                      </span>{" "}
                    </div>
                    <div className="media-body">
                    </div>
                    {info?.mobile}
                  </li>
                  <li className="media">
                    <div className="pull-left">
                      <span className="icon fa-stack fa-lg">
                        <i className="fa fa-envelope fa-stack-1x fa-inverse" />
                      </span>
                    </div>
                    {info?.email}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-bar">
        <div className="container">
          <div className="no-padding">
            <div className="clearfix payment-methods">
              <span style={{ color: "white" }}>
                ©2025 All Rights Reserved, Powered by
              </span>
              &nbsp;<a target="_blank" href="https://csgtech.in/">CSG Technosol Private Limited.</a>

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
