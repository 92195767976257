
function EmptyProducts() {
  return (
    <>
      <div className="empty-cart " id="empCart">
        <img
          width={2000}
          src="/assets/images/no-data.jpg"
          alt="Cart Empty"
        />
      </div>
    </>
  )
}

export default EmptyProducts